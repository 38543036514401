import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header'

class About extends Component{
    render(){
        return(
            <div>
            <Header />

                <div className="section section-padding">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 col-12 mb-lg-0 mb-3">
                        <div className="about-image"><img src="images/about-2.jpg" alt="" /></div>
                    </div>
                    <div className="col-lg-6 col-12 align-self-center mt-lg-0 mt-3">
                        <div className="about-content">

                            <div className="about-heading">
                                <h4 className="sub">Hazel Travels & Tours</h4>
                                <h2 className="title h1">Meeting your travel needs</h2>
                            </div>

                            <div className="desc">
                                <p>We are a travel agency that is committed to offering travel services of the highest quality. Combining our energy and enthusiasm, with years of experience. Our greatest success comes in serving large numbers of satisfied clients who have experienced the joy and inspiration of travel with us.</p>
                                <p>The company was established in 2019. Hazel Travels & Tours has concentrated its effort in producing quality travel. Responding to the needs of the times while anticipating the demands of the future.</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>



<div className="section section-padding pt-0">
            <div className="container">

                <div className="feature-wrap row">

                    <div className="feature col-lg-3 col-sm-6 col-12">
                        <div className="inner" style={{marginBottom: 20}}>

                            <span className="icon"><i className="fas fa-star"></i></span>

                            <div className="content">
                                <h4 className="title">Over 3,000 Happy Clients</h4>
                                <p className="desc">We are dedicated to providing our clients with the right services, we also guide our clients in using our expert knowledge and opinions during consultations. Most of our services are affordable because our good working relationship with other partners enables us to negotiate on behalf of our clients.</p>
                            </div>

                        </div>
                    </div>

                    <div className="feature col-lg-3 col-sm-6 col-12">
                        <div className="inner" style={{marginBottom: 20}}>

                            <span className="icon"><i className="fas fa-passport"></i></span>

                            <div className="content">
                                <h4 className="title">Over 500 Visas processed</h4>
                                <p className="desc">We don’t just process visas at random, we make our research on visa eligibility and validity and advice our clients accordingly.<br /><span style={{color: '#F8A00A', fontStyle: 'italic'}}>In all our Visa processing we have had 99% approval so far and counting.</span></p>
                            </div>

                        </div>
                    </div>


                    <div className="feature col-lg-3 col-sm-6 col-12">
                        <div className="inner" style={{marginBottom: 20}}>

                            <span className="icon"><i className="fas fa-user-friends"></i></span>

                            <div className="content">
                                <h4 className="title">16 Dedicated team</h4>
                                <p className="desc">Our team members understand their different roles and they back up the company’s Goals with their actions. They are committed to meeting the travel needs of all our clients, and each of our team members is reliable, honest, and self-motivated. </p>
                            </div>

                        </div>
                    </div>

                    <div className="feature col-lg-3 col-sm-6 col-12">
                        <div className="inner">

                            <span className="icon"><i className="far fa-clock"></i></span>

                            <div className="content">
                                <h4 className="title">24/7 Support</h4>
                                <p className="desc">We are here to assist you with our 24hours contact details <br />+234 (0) 806 660 3578 <br />+234 (0) 806 453 7989<br /><br /><span style={{color: '#F8A00A', fontWeight: '600'}}>Email Us: reservations.hazeltravels@gmail.com</span></p>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>


<div className="section section-padding">
            <div className="container">

                {/* <div className="section-title text-center w-100">
                    <h2 className="title h1">Our History</h2>
                    <p className="sub mb-5">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form</p>
                </div> */}

                <div className="timeline-wrap">


                    <div className="timeline row">

                        <div className="timeline-date col-lg-6 col-md-3 col-12">
                        <div className="image"><img src="images/about-1.jpg" alt="" /></div>
                        </div>

                        <div className="timeline-content col-lg-6 col-md-9 col-12">
                            <div className="content">

                                <div className="images row">
                                    <div className="col-sm-6 col-12"><img src="images/about-1.jpg" alt="" /></div>
                                    <div className="col-sm-6 col-12"><img src="images/why-us.jpg" alt="" /></div>
                                </div>
                                <h3 className="title">Our Mission</h3>
                                <div className="desc">
                                    <p>Thriving to delivering 100 percent to all our customers and to uphold our integrity in all our dealings</p>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="timeline row">

                        <div className="timeline-date col-lg-6 col-md-3 col-12">
                        <div className="image"><img src="images/about-3.jpg" alt="" /></div>
                        </div>

                        <div className="timeline-content col-lg-6 col-md-9 col-12">
                            <div className="content">

                            <div className="image"><img src="images/about-4.jpg" alt="" /></div>

                                <h3 className="title">Our Vision</h3>
                                <div className="desc">
                                    <p>To become one of the most reliable and trusted travel agencies in the world.</p>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
        
   <div className="section section-padding--sm bg-black">
            <div className="container">
                <div className="cta-content">
                    <h2 className="title h1 text-white">We meet all your travel needs</h2>
                    <Link to="/contact" className="btn btn-light-outline btn-hover-primary">Contact Us</Link>
                </div>
            </div>
        </div>     
            </div>
        );
    }
}

export default About;