import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './scrollToTop';

import Home from './Home'
// import Header from './Header'
import Footer from './Footer'
import About from './components/About'
import Services from './components/Services'
import Faqs from './components/Faqs'
import Contact from './components/Contact'
import Error from './components/Error'
import FlightDetails from './components/FlightDetails'
import HotelDetails from './components/HotelDetails'
import CarDetails from './components/CarDetails'
import CarDetailsDrop from './components/CarDetailsDrop'
import Thankyou from './components/Thankyou'
import Policies from './components/Policies'



function App() {

  return (
    
    <Router>
      
      <ScrollToTop />
      {/* <Header /> */}
      
        <Routes> {/* was Switch before. react router dom v6 now uses Routes in place of Switch*/}
          {/* <Route exact path = "/" element={<Home pass={ alertMsg } pass1={ alertMsg1 } pass3={ oneWay } pass4={ acounter } pass5={ ccounter } pass6={ icounter } pass7={ totalcount } pass8={ exceed } pass9={ limitad } pass10={ dropdown } pass11={ doneBtn } pass12={ defaulttab } pass13={ hotelt } pass14={ visast } pass15={ ridest } />} /> */}
          
          <Route exact path = "/" element={<Home />} />
          
          <Route path = "/about" element={<About />} />
          <Route path = "/contact" element={<Contact />} />
          <Route path = "/faqs" element={<Faqs />} />
          <Route path = "/policies" element={<Policies />} />
          <Route path = "/services" element={<Services />} />
          <Route path = "/flightdetails" element={<FlightDetails />} />
          <Route path = "/hoteldetails" element={<HotelDetails />} />
          <Route path = "/cardetails" element={<CarDetails />} />
          <Route path = "/cardetailsdrop" element={<CarDetailsDrop />} />
          <Route path = "/thankyou" element={<Thankyou />} />
          <Route path = "*" element={<Error />} />
        </Routes>
      <Footer />
      
    </Router>
    
  );
}

export default App;
