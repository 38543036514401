import { Link,  createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import Input from 'react-phone-number-input/input'
import Header from '../Header'


function HotelDetails() {

const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const [email, setEmail] = useState('');
const [phone, setPhone] = useState('');
const [country, setCountry] = useState('');
const [state, setState] = useState('');
const [zipCode, setZipCode] = useState('');
const [homeAddress, setHomeAddress] = useState('');
//check if hotelconfirmbooking is clicked
const [clickedhotel, setClickedhotel] = useState(false);

//country list
const options = useMemo(() => countryList().getData(), [])

const changeHandler = country => {
  setCountry(country.label)
}

const navigate = useNavigate();

const [searchparams] = useSearchParams();

const hotelTrue=()=> {
  setClickedhotel(clickedhotel => true)
}


const confirmBooking=(e)=> {
    e.preventDefault();

    navigate({
        pathname: "/thankyou",
        search: createSearchParams({

            hotel_name: searchparams.get("hotel_name"),
            check_in: searchparams.get("check_in"),
            check_out: searchparams.get("check_out"),
            room_no: searchparams.get("room_no"),
            guest_no: searchparams.get("guest_no"),

          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          country: country,
          state: state,
          zip_code: zipCode,
          home_address: homeAddress,
          clicked_hotel: clickedhotel,
        }).toString()
      });
}

  

    return(
        <>
<Header />

<section className="pt80 pb80 booking-section login-area">
  <div className="container">
    <div className="row">
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="listing-item ">
          <article className="TravelGo-category-listing fl-wrap">
          <div className="TravelGo-category-img"> <a href="hotel-detailed.html"><img src="images/waiting.jpg" alt="" /></a>
              {/* <div className="TravelGo-category-opt">
                <div className="listing-rating card-popup-rainingvis" data-starrating2="5"><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></div>
                <div className="rate-className-name">
                  <div className="score"><strong>Very Good</strong>27 Reviews </div>
                  <span>5.0</span> </div>
              </div> */}
            </div>
            <div className="TravelGo-category-content fl-wrap title-sin_item">
              <div className="TravelGo-category-content-title fl-wrap">
                <div className="TravelGo-category-content-title-item">
                  <h3 className="title-sin_map"><a href="hotel-detailed.html">Complete Your Booking</a></h3><br />
                  <h3 className="title-sin_map" style={{color: '#F8A00A', width: '100%', marginBottom: 20}}>Your Hotel Details</h3>

                  <div style={{width: '100%', height: 'auto', float: 'left'}}>
                    <p id="style-flights"> <span style={{fontSize: 13, fontWeight: '600'}}>Hotel Name: { searchparams.get("hotel_name") } </span></p>
                    <p id="style-flights"> <span style={{fontSize: 13, fontWeight: '600'}}>Check-in Date: { searchparams.get("check_in") } </span></p>
                    <p id="style-flights"> <span style={{fontSize: 13, fontWeight: '600'}}>Check-out Date: { searchparams.get("check_out") } </span></p>
                    <p id="style-flights"> <span style={{fontSize: 13, fontWeight: '600'}}>Rooms: { searchparams.get("room_no") } </span></p>
                    <p id="style-flights"> <span style={{fontSize: 13, fontWeight: '600'}}>Guest: { searchparams.get("guest_no") } </span></p>
                  </div>
                </div>
              </div>
              <p style={{color: '#666666', fontSize: 16}}>You are few steps away. Fill in your personal details with the form to confirm your booking</p>
              
              
            </div>
          </article>
        </div>
        <div className="listing-item">
          <article className="TravelGo-category-listing fl-wrap">
            <div className="TravelGo-category-content fl-wrap title-sin_item">
              <div className="TravelGo-category-content-title fl-wrap NeedHelp">
                <div className="TravelGo-category-content-title-item">
                  <h3 className="title-sin_map"><a href="hotel-detailed.html">Need Help?</a></h3>
                  <div className="TravelGo-category-location fl-wrap"></div>
                </div>
              </div>
              <div className="NeedhelpSection">
                
                <p style={{color: '#666666', fontSize: 16}}>Our team advisor are 24/7 at your service to help you.</p>
                <ul>
                  <li><span><i className="fas fa-phone-volume"></i></span> (234) 806-660-3578</li>
                  <li><span><i className="far fa-envelope"></i></span> reservations.hazeltravels
@gmail.com</li>
                  
                </ul>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div className="col-lg-8 col-md-6 col-sm-12">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="login-box Booking-box">
              <div className="login-top">
                <h3>Your Personal Information</h3>
                <p>Fill in your complete details to confirm your booking.</p>
              </div>
              <form className="login-form" onSubmit={ confirmBooking }>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 email">
                    <label>First Name</label>
                    <input type="text" name="text" placeholder="First Name" value={ firstName } onChange={(e)=> setFirstName(e.target.value)} required />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 email">
                    <label>Last Name</label>
                    <input type="text" name="text" placeholder="Last Name" value={ lastName } onChange={(e)=> setLastName(e.target.value)} required />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 email">
                    <label>Email</label>
                    <input type="email" name="email" placeholder="Enter your email here" value={ email } onChange={(e)=> setEmail(e.target.value)} required />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 password">
                    <label>Phone Number</label>
                    <Input placeholder="Enter Phone Number" value={ phone } onChange={ setPhone } />
                    {/* <input type="phone" name="text" placeholder="Enter Phone Number" value={ phone } onChange={(e)=> setPhone(e.target.value)} required /> */}
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 password">
                    <label>Country</label>
                    <Select options={ options } value={ country.label } onChange={ changeHandler } />
                    {/* <input type="text" name="text" placeholder="Country" value={ country } onChange={(e)=> setCountry(e.target.value)} required /> */}
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12 password">
                    <label>State</label>
                    <input type="text" name="text" placeholder="State" value={ state } onChange={(e)=> setState(e.target.value)} required />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 email">
                    <label>Zip Code</label>
                    <input type="text" name="text" placeholder="Zip Code" value={ zipCode } onChange={(e)=> setZipCode(e.target.value)} required />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 email">
                    <label>Home Address</label>
                    <input type="text" name="text" placeholder="Home Address" value={ homeAddress } onChange={(e)=> setHomeAddress(e.target.value)} required />
                  </div>
                  <div className="col-md-12 d-flex justify-content-between">
                   
                  </div>
                  
                  <div className="col-md-12 d-flex justify-content-between">
                    <div className="chqbox">
                      <input type="checkbox" name="rememberme" id="rmme" required />
                      <label for="rmme"> By continuing, you agree to the Terms and Conditions.</label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button className="myconfirmbutton" type="submit" name="button" onClick={ hotelTrue }>Confirm Booking</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

{/* CTA */}
<div className="section section-padding--sm bg-black">
            <div className="container">

                <div className="cta-content">
                    <h2 className="title h1 text-white">We meet all your travel needs</h2>
                    <Link to="/contact" className="btn btn-light-outline btn-hover-primary">Contact Us</Link>
                </div>

            </div>
        </div>
        
        </>
    );
}

export default HotelDetails;