import { useSearchParams } from 'react-router-dom';
import { send } from 'emailjs-com'
import React, { useState } from 'react'
import Header from '../Header'


function ThankyouPage() {

    const [searchparams] = useSearchParams();

    //considering which booking confirmation to show
    const [flightconfirmation, setFlightconfirmation] = useState(searchparams.get("clicked_flight"));
    const [hotelconfirmation, setHotelconfirmation] = useState(searchparams.get("clicked_hotel"));
    const [rideconfirmation, setRideconfirmation] = useState(searchparams.get("clicked_ride"));
    const [ridedropconfirmation, setRidedropconfirmation] = useState(searchparams.get("clicked_ridedrop"));


    // const [isconfirmed, setIsconfirmed] = useState(false);

    // const bookingConfirmed=()=> {
    //     isconfirmed = true;
    //     setIsconfirmed(isconfirmed => true)

    // }


    // console.log("Flight Clicked: " + flightconfirmation);
    // console.log("Hotel Clicked: " + hotelconfirmation);
    // console.log("Ride Clicked: " + rideconfirmation);
    // console.log("Drop Clicked: " + ridedropconfirmation);
    // console.log(searchparams.get("clicked_flight"));


    const [sendEmail, setSendEmail] = useState({
        //Flight submission details
        going_from: searchparams.get("from"),
        going_to: searchparams.get("to"),
        departing_when: searchparams.get("departing"),
        return_date: searchparams.get("returning"),
        flght_class: searchparams.get("class"),
        p_adult: searchparams.get("adult"),
        p_child: searchparams.get("child"),
        p_infant: searchparams.get("infant"),
        all_passengers: searchparams.get("all_passengers"),

        //Hotel submission details
        name_of_hotel: searchparams.get("hotel_name"),
        check_in_date: searchparams.get("check_in"),
        check_out_date: searchparams.get("check_out"),
        no_of_rooms: searchparams.get("room_no"),
        no_of_guests: searchparams.get("guest_no"),

        //Car Ride (Pick-up) submission details
        pickup_airport: searchparams.get("pickup_location"),
        pickup_destination: searchparams.get("pickup_destination"),
        ride_car_class: searchparams.get("car_class"),
        ride_pickup_date: searchparams.get("pickup_date"),
        ride_pickup_time: searchparams.get("pickup_time"),

        //Car Ride (Drop-off) submission details
        dropoff_location: searchparams.get("your_destination"),
        dropoff_airport: searchparams.get("airport"),
        dropoff_carclass: searchparams.get("car_class"),
        ride_dropoff_date: searchparams.get("dropoff_date"),
        ride_dropoff_time: searchparams.get("dropoff_time"),

        //Personal Info.
        first_name: searchparams.get("first_name"),
        last_name: searchparams.get("last_name"),
        user_email: searchparams.get("email"),
        user_phone: searchparams.get("phone"),
        user_address: searchparams.get("home_address"),
        user_state: searchparams.get("state"),
        user_zipcode: searchparams.get("zip_code"),
        user_country: searchparams.get("country"),

    // from_name: "",
    // reply_to: "",
    // message: "",
    
  });


    send(
      'service_xtt423r', //service ID
      'template_s3a4mu7', //Template ID
      sendEmail,
      'ja8vDrC7BOfxcV-zk' //User ID
    ).then((response)=> {
      console.log("Success!", response.status, response.text);
    
      
    }).catch((err) => {
      console.log("Failed.", err);
    
    });



//   emailjs.sendForm('service_xtt423r', 'template_s3a4mu7', form.current, '').then((result) => {
//       console.log(result.text);
//     }, (error) => {
//       console.log(error.text);
//     });



    return(
        <>
        <Header />

            <section className="pt80 pb60 booking-section login-area thanksYou">
        <div className="container">
            <div className="row">
            
            
            
            
        <div className="col-lg-12 col-md-6 col-sm-12">
                <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="login-box Booking-box">
                    <div className="login-top">
                        <h3>Booking Confirmation</h3>
                        <p>Thank you for filling out your details! Kindly use the <span style={{fontWeight: 'bold'}}>Confirm Button</span> below this page to confirm your information or click the <span style={{fontWeight: 'bold'}}>Edit Info</span> button to update your information.</p>
                    </div>

                    { flightconfirmation && (
                    <div className="thankyou-details">
                        <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Going from: { searchparams.get("from") } </span></p>
                        <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>To: { searchparams.get("to") } </span></p>
                        <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Departure Date: { searchparams.get("departing") } </span></p>
                        <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Return Date: { searchparams.get("returning") } </span></p>

                        <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Flight Class: { searchparams.get("class") } </span></p>
                        <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Adult: { searchparams.get("adult") } </span></p>
                        <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Child: { searchparams.get("child") } </span></p>
                        <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Infant: { searchparams.get("infant") } </span></p>
                        <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Total Passengers: { searchparams.get("all_passengers") } </span></p>
                    </div>
                    )}

                    { hotelconfirmation && (
                        <div className="thankyou-details">
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Hotel Name: { searchparams.get("hotel_name") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Check-in Date: { searchparams.get("check_in") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Check-out Date: { searchparams.get("check_out") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Rooms: { searchparams.get("room_no") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Guest: { searchparams.get("guest_no") } </span></p>
                        </div>
                    )}

                    { rideconfirmation && (
                        <div className="thankyou-details">
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Airport: { searchparams.get("pickup_location") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Destination: { searchparams.get("pickup_destination") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Car Class: { searchparams.get("car_class") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Pick-up Date: { searchparams.get("pickup_date") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Pick-up Time: { searchparams.get("pickup_time") } </span></p>  
                        </div>
                    )}

                    { ridedropconfirmation && (
                        <div className="thankyou-details">
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Your Location: { searchparams.get("your_destination") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Airport: { searchparams.get("airport") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Car Class: { searchparams.get("car_class") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Drop-off Date: { searchparams.get("dropoff_date") } </span></p>
                            <p id="style-flights-thankyou"> <span style={{fontSize: 13, fontWeight: '600'}}>Drop-off Time: { searchparams.get("dropoff_time") } </span></p>
                        </div>
                    )}
                    

                        <div className="login-top cardInfo">
                            <h3>Your Travel Information</h3>
                            <p>Below is the details of your travel information.</p>
                        </div>           
                    
                    
                    
                    
        <table className="table table-striped">
                        <tbody>
                        {/* <tr>
                            <td className="bookex">Booking number:</td>
                            <td className="bookex">HTT 21548YB</td>
                        </tr> */}
                        <tr>
                            <td className="bookex">First Name:</td>
                            <td className="bookex">{ searchparams.get("first_name") }</td>
                        </tr>
                        <tr>
                            <td className="bookex">Last Name:</td>
                            <td className="bookex">{ searchparams.get("last_name") }</td>
                        </tr>
                        <tr>
                            <td className="bookex">E-mail address:</td>
                            <td className="bookex">{ searchparams.get("email") }</td>
                        </tr>
                        <tr>
                            <td className="bookex">Phone Number:</td>
                            <td className="bookex">{ searchparams.get("phone") }</td>
                        </tr>
                        <tr>
                            <td className="bookex">Address:</td>
                            <td className="bookex">{ searchparams.get("home_address") }</td>
                        </tr>
                        
                        <tr>
                            <td className="bookex">State / City:</td>
                            <td className="bookex">{ searchparams.get("state") }</td>
                        </tr>                 
                        
                        <tr>
                            <td className="bookex">ZIP code:</td>
                            <td className="bookex">{ searchparams.get("zip_code") }</td>
                        </tr>                 
                        
                        <tr>
                            <td className="bookex">Country:</td>
                            <td className="bookex">{ searchparams.get("country") }</td>
                        </tr>
                    </tbody>
                    </table>
                    
                    {/* <div className="confirm-booking">
                        <button className="btn btn-primary btn-lg btn-grad" type="submit" onClick={ sendEmail }>Confirm Booking</button>
                        <button className="btn btn-primary btn-lg btn-grad" type="submit">Edit Informatio</button>
                    </div> */}
                    
                        
                        {/* {isconfirmed && ( */}
                        <div className="login-top cardInfo">
                            <h3>Payment</h3>
                            <p>Hazel Travels & Tours will get back to you via email concerning how to make payment. Kindly check out for our email as soon as possible.</p>
                        </div>              
                        
                    
        
        
                              
                    </div>
                    
                </div>
                </div>
            </div>  
 
            </div>
        </div>
        </section>
    </>
    );
}

export default ThankyouPage;