import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component{
    render() {
        return (
            <div>
                {/*=== Footer ====*/}
<footer className="footer footer-dark pt-6 position-relative">
  <div className="footer-content">
    <div className="container">
      <div className="row"> 

        <div className="col-md-3 col-sm-6 order-sm-1">
          <div className="widget address"> <Link to="/" className="footer-logo mb-3 d-block">
            
            <img src="images/logo-footer.png" alt = "Logo" /> 

            </Link>
            <p>Hazel Travels & Tours is a travel agency that is committed to offering travel services of the highest quality. The company was established in 2019 and has concentrated its efforts in providing quality travel service.</p>
          </div>
        </div>

        <div className="col-md-2 col-sm-4 order-sm-3">
          <div className="widget">
            <h6>Quick LInks</h6>
            <ul className="nav flex-column">
              <li className="nav-item"><Link to="/about" className="nav-link">About</Link></li>
              <li className="nav-item"><Link to="/services" className="nav-link">Services</Link></li>
              <li className="nav-item"><Link to="/faqs" className="nav-link">FAQs</Link></li>
              {/* <li className="nav-item"><Link to="contact" className="nav-link">Contact</Link></li> */}
              
            </ul>
          </div>
        </div>

        <div className="col-md-2 col-sm-4 order-sm-4">
          <div className="widget">
            <h6>Company</h6>
            <ul className="nav flex-column primary-hover">
              <li className="nav-item"><Link to="/about" className="nav-link">Hazel Travels</Link></li>
              <li className="nav-item"><a href="https://forms.gle/g12yVqqeMhwDHctY8" className="nav-link">HT&T Jobs</a></li>
              <li className="nav-item"><Link to="/" className="nav-link">Bookings</Link></li>
              <li className="nav-item"><Link to="/policies" className="nav-link">Refund Policies</Link></li>
              
              
            </ul>
          </div>
        </div>

        <div className="col-md-3 col-sm-6 order-sm-2">
          <div className="widget address">
            <ul className="list-unstyled">
              <li className="media mb-3"><i className="fas fa-map-marked-alt mr-3 display-8"></i>1449 Constitution Avenue, Central Business District Abuja </li>
              <li className="media mb-3"><i className="mr-3 display-8 fas fa-phone"></i> (234) 806-660-3578 </li>
              <li className="media mb-3"><i className="mr-3 display-8 far fa-envelope"></i> reservations.hazeltravels<br />@gmail.com</li>
              <li className="media mb-3"><i className="mr-3 display-8 far fa-clock"></i>
                <p>Mon - Sat: <strong>08:00 - 18:30</strong></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="divider mt-3"></div>

  <div className="footer-copyright py-3">
    <div className="container">
      <div className="d-md-flex justify-content-between align-items-center py-3 text-center text-md-left"> 
        
        <div className="copyright-text" style={{fontSize: 14}}>©2022 All Rights Reserved. with <span style = {{color: '#F8A00A'}}>&hearts;</span> by <a href="https://mywebprojects.com.ng" style={{fontSize: 14}}> MyWeb Projects.</a></div>
         
        
      </div>
    </div>
  </div>
</footer>
            </div>
        );
    }
}


export default Footer;