import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header'


class Services extends Component{
    render() {
        return(
            <div>
			<Header />
			
                <div class="innerpage-banner left bg-overlay-dark-7 py-7" style={{background: 'url(images/07.jpg) repeat', backgroundSize: 'cover'}}>
  <div class="container">
    <div class="row all-text-white">
      <div class="col-md-12 align-self-center">
        <h1 class="innerpage-title">Our Services</h1>
        <h6 class="subtitle">Hazel Travels & Tours - Services</h6>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active"><Link to="/"><i class="ti-home"></i> Home</Link></li>
            <li class="breadcrumb-item">Our Services</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>

	<section class="service">
		<div class="container">
			<div class="row">
				<div class="col-sm-6 col-md-4">
					<div class="feature-box h-100 icon-grad">
						<div class="feature-box-icon"><i class="fas fa-comment"></i></div>
						<h3 class="feature-box-title">Travel Consultation</h3>
						<p class="feature-box-desc">Our well trained team help our clients by guiding them on their travel plans, what to do and what not to do depending on the country the client intend visiting. </p>
					</div>
				</div>
				<div class="col-sm-6 col-md-4">
					<div class="feature-box h-100 icon-grad">
						<div class="feature-box-icon"><i class="fas fa-plane"></i></div>
						<h3 class="feature-box-title">Flight Bookings</h3>
						<p class="feature-box-desc">From anyway in the world, we make flight booking easy and convenient for our clients by seamlessly booking their ( local & International ) tickets and sending their flight itineraries via email or WhatsApp messenger. HT&T also give room for clients to manage their tickets. Hence, we can open and change travel dates on client tickets on request </p>
					</div>
				</div>
				<div class="col-sm-6 col-md-4">
					<div class="feature-box h-100 icon-grad">
						<div class="feature-box-icon"><i class="fas fa-hotel"></i></div>
						<h3 class="feature-box-title">Hotel Reservations</h3>
						<p class="feature-box-desc">On behalf of our clients we make the best hospitality arrangements whenever we get a request. The joy of every trip is securing a nice and elegant place to rest after each day’s activities. </p>
					</div>
				</div>
				<div class="col-sm-6 col-md-4">
					<div class="feature-box h-100 icon-grad">
						<div class="feature-box-icon"><i class="fas fa-route"></i></div>
						<h3 class="feature-box-title">Tour & Holiday Packages</h3>
						<p class="feature-box-desc">We do the heavy lifting for our clients by making all necessary prearrangements regards their trip, from airport transfers to hotel accommodations, breakfast, flight tickets and tours. HT&T is known for arranging amazing travel experiences at fare prices for their clients </p>
					</div>
				</div>
				<div class="col-sm-6 col-md-4">
					<div class="feature-box h-100 icon-grad">
						<div class="feature-box-icon"><i class="fas fa-info"></i></div>
						<h3 class="feature-box-title">Travel Insurance</h3>
						<p class="feature-box-desc">We advise travelers to make sure they are covered from different risks while traveling by getting a travel insurance before embarking on their trips. Travel Insurance covers medical expenses, lost luggages, flight cancellations and other losses that might be incurred while traveling. </p>
					</div>
				</div>
				<div class="col-sm-6 col-md-4">
					<div class="feature-box h-100 icon-grad">
						<div class="feature-box-icon"><i class="fas fa-car"></i></div>
						<h3 class="feature-box-title">Car Hire</h3>
						<p class="feature-box-desc">We give our clients the comfort of riding safely to their destinations. Luxury rides, family or group rides, compact or economy rides, we render one of the best car hire services for a short period of time to our clients and the public at large. </p>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="service-block">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 col-lg-6 align-self-center">
					<div class="title text-left pb-4 p-lg-0 ">
						<h2>Our Success Story</h2>
						<p>Combining our energy and enthusiasm, with years of experience. Our greatest success comes in serving large numbers of satisfied clients who have experienced the joy and inspiration of traveling with us.</p>
						<div class="row">
							<div class="col-6">
								<ul class="list-group list-group-borderless list-group-icon-primary-bg">
									<li class="list-group-item"><i class="fa fa-check"></i>Over 3000 Happy Client </li>
									<li class="list-group-item"><i class="fa fa-check"></i>Over 500 Approved Visas</li>
									<li class="list-group-item"><i class="fa fa-check"></i>Numerous Travel collaborations and partnerships </li>
								</ul>
							</div>
							<div class="col-6">
								<ul class="list-group list-group-borderless list-group-icon-primary-bg">
									<li class="list-group-item"><i class="fa fa-check"></i>Job openings & Career Opportunities  </li>
									<li class="list-group-item"><i class="fa fa-check"></i>Unlimited travel deals</li>
									<li class="list-group-item"><i class="fa fa-check"></i>26/7 Dedicated Support </li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-10 col-lg-6 mx-md-auto align-self-center">
					<img class="img-fluid" src="images/about-1.jpg" alt="our services" />
				</div>
			</div>
		</div>
	</section>

	<div class="divider m-0"> </div>

	<section class="pricing pricing-center whiteBG">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-8 mx-auto mb-5">
        <div class="title text-center">
          <h2> Our Tour Packages</h2>
          <p>Find below affordable tour packages. We are also open to preparing tour packages on request</p>
        </div>
      </div>
    </div>
    <div class="row"> 
      
      <div class="col-md-4">
        <div class="pricing-box h-100">
          <h5>Malaysia Package</h5>
          <div class="plan-price"> <span class="price text-grad"> <sup class="text-grad">$</sup>750 </span> / Person </div>
          <p><i class="fa fa-check"></i> 6 nights accomodation at Ibis Style Fraser<br />
		  <i class="fa fa-check"></i> Daily breakfast<br />
		  <i class="fa fa-check"></i> Return airport transfers<br />
		  <i class="fa fa-check"></i> Malaysia city tour - Cable car ride @ Genting Island Sunway Lagoon. (Ticket only)</p>
          <a class="btn btn-outline-light mt-4" href="https://api.whatsapp.com/send?phone=2348066603578&text=Malaysia Package Enquiry">Order now!</a> </div>
      </div>
      
      <div class="col-md-4">
        <div class="pricing-box h-100 shadow no-border box">
          <div class="ribbon"><span>POPULAR</span></div>
          <h5>Dubai Package</h5>
          <div class="plan-price"> <span class="price text-grad"> <sup class="text-grad">$</sup>572 </span> / Person </div>
          <p><i class="fa fa-check"></i> 7 nights accomodation with breakfast in Ramada Hotel by Wyndham<br />
		  <i class="fa fa-check"></i> Return airport transfer<br />
		  <i class="fa fa-check"></i> Half-day Dubai City Tour<br />
		  <i class="fa fa-check"></i> Marina Dhow Cruise dinner<br />
		  <i class="fa fa-check"></i> All tours and transfers on a private basis.</p>
          <a class="btn btn-grad mt-4" href="https://api.whatsapp.com/send?phone=2348066603578&text=Dubai Package Enquiry">Order now!</a> </div>
      </div>
      <div class="col-md-4">
        <div class="pricing-box h-100">
          <h5>Singapore Package</h5>
          <div class="plan-price"> <span class="price text-grad"> <sup class="text-grad">$</sup>1650 </span> / Person </div>
          <p><i class="fa fa-check"></i> 7 nights accomodation with breakfast in Ramada Hotel by Wyndham<br />
		  <i class="fa fa-check"></i> Return airport transfer.<br />
		  <i class="fa fa-check"></i> Singapore city tour with English speaking tour guide<br />
		  <i class="fa fa-check"></i> Full day Sentosa Island (includes Cable car, Madam Tussauds admission + images of Singapore + Boat ride + Ult Film Star + 4D Marvel + Luge and Skyride + Wings of Time Universal Studio)<br />
			MBS Sky-Park + Gardens by the Bay (Flower Dome and Cloud Forest)<br />
            <i class="fa fa-check"></i> All tours and transfers on a private basis.</p>
          <a class="btn btn-outline-light mt-4" href="https://api.whatsapp.com/send?phone=2348066603578&text=Singapore Package Enquiry">Order now!</a> </div>
      </div>
    </div>
  </div>
</section>
            </div>
        );
    }
}


export default Services;