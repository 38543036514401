import React, { useState } from 'react';
import { send } from 'emailjs-com'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header'



function Contact() {

  const navigate = useNavigate();

  const [responseText, setResponseText] = useState("");
  const [responseError, setResponseError] = useState("");

  const [sendEmail, setSendEmail] = useState({
    from_name: "",
    reply_to: "",
    message: "",
    
  });

  const onSubmit=(e)=> {
    e.preventDefault();

    send(
      'service_xtt423r', //service ID
      'template_yrpxn76', //Template ID
      sendEmail,
      'ja8vDrC7BOfxcV-zk' //User ID
    ).then((response)=> {
      console.log("Success!", response.status, response.text);
      setResponseText("Message sent successfully!");
      
    }).catch((err) => {
      console.log("Failed.", err);
      setResponseError("Could not send email. Try again");
    });

  };

  const handleChange=(e)=> {
    setSendEmail({...sendEmail, [e.target.name]: e.target.value});
  }

  // const [emptyStatus, setEmptyStatus] = useState(true);
  const [sendStatus, setSendStatus] = useState(false);

  const checkStatus=()=> {
    if(responseText !== "Message sent successfully!"){
      // setFailureStatus(emptyStatus => false);
      setSendStatus(successStatus => false);
      navigate({
        pathname: "/contact"
      });
    }else{
      // setFailureStatus(emptyStatus => true);
      setSendStatus(successStatus => true)
    }
  }


  // const msgStatus=()=> {
  //  if(response.status === "OK"){
  //   setSuccessStatus(successStatus == "Message sent successfully!");
  // }else{
  //   setFailureStatus(failureStatus == "Message could not be sent. Try again")
  // }
  
  // }

  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('', '', form.current, '').then((result) => {
  //     console.log(result.text);
  //   }, (error) => {
  //     console.log(error.text);
  //   });
  // };
    
        return(
            <div>
              <Header />
                <div className="innerpage-banner left bg-overlay-dark-7 py-7" style={{background: 'url(images/07.jpg) repeat', backgroundSize: 'cover'}}>
  <div className="container">
    <div className="row all-text-white">
      <div className="col-md-12 align-self-center">
        <h1 className="innerpage-title">Contact Us</h1>
        <h6 className="subtitle">Hazel Travels & Tours - Contact Us</h6>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active"><Link to="/"><i className="ti-home"></i> Home</Link></li>
            <li className="breadcrumb-item">Contact Us</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>

<section className="contact-area contact-pg-section">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 col-md-12">
        <div className="get-in-touch">
          <div className="sec-title">
            <h3>Get In Touch</h3>
            <p>Use the form below for more enquiry about Hazel Travels & Tours.</p>
          </div>
          <div className="contact-form">
            {/* <form id="ajax-contact" method="post" action="send.php"> */}
            <form onSubmit={ onSubmit }>
              <div className="row">
                <div className="col-md-12">
                  <label for="name">Full Name</label>
                  <p>
                    <input type="name" id="name" placeholder="Enter your full name" name="from_name" value={ sendEmail.from_name } onChange={ handleChange } required />
                  </p>
                </div>
                <div className="col-md-12">
                  <label for="email">Email</label>
                  <p>
                    <input type="email" id="email" placeholder="Your email address" name="reply_to" value={ sendEmail.replyEmail } onChange={ handleChange } required />
                  </p>
                </div>
                <div className="col-md-12">
                  <label for="message">Message</label>
                  <p>
                    <textarea name="message" id="message" placeholder="Your message" value={ sendEmail.message } onChange={ handleChange } required></textarea>
                  </p>
                </div>
                <div className="response-text-container">{ sendStatus ? responseText : responseError}</div>
                
                <div className="col-md-12">
                  <button className="btn btn-primary btn-lg btn-grad" type="submit" onClick={ checkStatus }>Send Message</button>
                </div>
              </div>
              <div id="form-messages"></div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12"> 
	  
	  
	  
<div className="contact-info">
                            <div>
                                <div className="icon">
                                    <i className="fas fa-mobile-alt"></i>
                                </div>
                                <div className="details">
                                    <h5>Call or Chat</h5>
                                    <p>+234 806 660 3578</p>
                                </div>
                            </div>
                            <div>
                                <div className="icon">
                                   <i className="far fa-envelope"></i>
                                </div>
                                <div className="details">
                                    <h5>Email</h5>
                                    <p>reservations.hazeltravels@gmail.com</p>
                                </div>
                            </div>
                            <div>
                                <div className="icon">
                                    <i className="fas fa-headphones-alt"></i>
                                </div>
                                <div className="details">
                                    <h5>P.O Box</h5>
                                    <p>901101 - Federal Capital Territory, Abuja</p>
                                </div>
                            </div>
                            <div>
                                <div className="icon">
                                   <i className="fas fa-street-view"></i>
                                </div>
                                <div className="details">
                                    <h5>Location</h5>
                                    <p>1449 Constitution Avenue, Central Business District Abuja</p>
                                    
                                </div>
                            </div>
                        </div>	  
	  
	  
	  
	  
	  
	  </div>
      

    </div>
  </div>
</section>
            </div>
        );
  
}


export default Contact;