import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header'

const Error = ()=>{
    return(
        <div>
            <Header />
            <section className="error-area" style={{marginBottom: 50}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-box text-center">
                            <img src="images/error-404.png" alt="Error" style={{width: '20%', height: '50%', marginTop: 50}} />
                            <h4 style={{color: '#888'}}>Oops... Something's missing!</h4>
                            <p style={{color: '#888'}}>The page you're looking for is not found</p>
                            <Link to="/"><i className="la la-home"></i>Back To Homepage</Link>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        </div>
    );
}

export default Error;