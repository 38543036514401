import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header'


class Policies extends Component{

    constructor(props){
        super(props);
        this.state = {
            activeQuestion: false
        };
        this.toggleClass.bind(this)
    }

    toggleClass(){
        const currentState = this.state.activeQuestion;
        this.setState({ active: !currentState });
    }

    render(){
        let policies = [
            {
                question: "Flight Ticket Refund Policy",
                answer: "Refunds for flight tickets solely depend on the type of ticket and the airline. Refund processing time also depends on the airline's processing time."
            },
            {
                question: "Refundable Tickets",
                answer: "- Tickets canceled by airlines due to technical reasons, in this case, tickets will be refunded fully to the passenger (aside from inital flight booking charges) \n- No-show ticket refunds, tickets will be partially refunded in this case. \n- Tickets canceled by a passenger for any reason whatsoever, the ticket will be partially refunded in this case.",
                // answer: "- No-show ticket refunds, tickets will be partially refunded in this case.",
                // answer: "- Tickets canceled by a passenger for any reason whatsoever, the ticket will be partially refunded in this case."
            },
            {
                question: "",
                answer: "- No-show ticket refunds, tickets will be partially refunded in this case."
            },
            {
                question: "",
                answer: "- Tickets canceled by a passenger for any reason whatsoever, the ticket will be partially refunded in this case."
            },
            {
                question: "Non-Refundable Tickets",
                answer: "Hazel Travels & Tours will not be held responsible for tickets that are not refundable by any airline."
            }
            
        ];
        return(
            <div>
            <Header />
            
            <div class="innerpage-banner left bg-overlay-dark-7 py-7" style={{background: 'url(images/07.jpg) repeat', backgroundSize: 'cover'}}>
                <div class="container">
                    <div class="row all-text-white">
                    <div class="col-md-12 align-self-center">
                        <h1 class="innerpage-title">Refund Policy</h1>
                        <h6 class="subtitle">Hazel Travels & Tours - Refund Policy</h6>
                        <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item active"><Link to="/"><i class="ti-home"></i> Home</Link></li>
                            <li class="breadcrumb-item">Refund Policy</li>
                        </ol>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>

            <section className="faq-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        
                        <div className="faq-box">
                            
                           <div className="card">
                              {/*    <div className="card-header">
                                    <a href="#toggle" class="d-flex justify-content-between" data-toggle="collapse" data-target="#faq1">
                                </div> */}
            
            <div className="card-header">
                
                {
                policies.map((items) => {
                    return (
                        <div className="items">
                            
                            <strong
                            data-question={items.question}
                            className={this.state.activeQuestion ? "active" : null}
                            onClick={this.toggleClass} >
                            
                            <a href="#toggle" class="d-flex justify-content-between" data-toggle="collapse" data-target="#faq4">
                                {items.question}
                                <i class="la la-plus"></i>
                            </a>
                        
                            </strong>
                            <div id="faq1" class="collapse show" data-parent="#accordion">
                                <div class="card-body">
                                    <p>{items.answer}</p>
                                </div>
                            </div>
                        </div>
                        
                    );
                })
            }
            </div>
            <p style={{marginTop: 20, padding: 10, background: '#f3f3f3'}}>Flight Refund:
                <a href="https://forms.office.com/r/k9VKbGcxht" style={{color: '#F8A00A', textDecoration: 'underline'}}> Proceed with refund</a>
            </p>
            <p style={{marginTop: 20, padding: 10, background: '#f3f3f3'}}>Visa & Tour Packages Refund:
                <a href="https://forms.office.com/r/n04cqST5vJ" style={{color: '#F8A00A', textDecoration: 'underline'}}> Proceed with visa & tour packages refund</a>
            </p>
            </div></div></div></div></div>
            
            </section>
        </div>
        
        );
    }
}

export default Policies;