import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header'


class Faqs extends Component{

    constructor(props){
        super(props);
        this.state = {
            activeQuestion: false
        };
        this.toggleClass.bind(this)
    }

    toggleClass(){
        const currentState = this.state.activeQuestion;
        this.setState({ active: !currentState });
    }

    render(){
        let faqs = [
            {
                question: "Q. Do you do airport pickup for Local trips?",
                answer: "Yes! Airport pickups are available for only Lagos and Abuja for now."
            },
            {
                question: "Q. Can you book multiple trips for persons traveling for occassion?",
                answer: "Yes we can. Tip: Multiple trips are mostly cheaper than single trips. Single trips are mostly cheaper than One-way trips."
            },
            {
                question: "Q. I want to make hotel reservations, how do I book?",
                answer: "You can book via our website hazeltravelsandtours.com.ng or send an email to reservations.hazeltravels@gmaill.com"
            },
            {
                question: "Q. Do you process work visas?",
                answer: "Yes! You can check for the available work visas on our website or social media pages."
            },
            {
                question: "Q. How long does Tanzania Visa processing take?",
                answer: "Express Tanzania visa processing takes 24 hours while normal Tanzania visa processing takes up to 3 to 4 weeks."
            }
        ];
        return(
            <div>
            <Header />
            
            <div class="innerpage-banner left bg-overlay-dark-7 py-7" style={{background: 'url(images/07.jpg) repeat', backgroundSize: 'cover'}}>
                <div class="container">
                    <div class="row all-text-white">
                    <div class="col-md-12 align-self-center">
                        <h1 class="innerpage-title">FAQ</h1>
                        <h6 class="subtitle">Hazel Travels & Tours - FAQ</h6>
                        <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item active"><Link to="/"><i class="ti-home"></i> Home</Link></li>
                            <li class="breadcrumb-item">FAQ</li>
                        </ol>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>

            <section className="faq-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        
                        <div className="faq-box">
                            
                           <div className="card">
                              {/*    <div className="card-header">
                                    <a href="#toggle" class="d-flex justify-content-between" data-toggle="collapse" data-target="#faq1">
                                </div> */}
            
            <div className="card-header">
                
                {
                faqs.map((items) => {
                    return (
                        <div className="items">
                            
                            <strong
                            data-question={items.question}
                            className={this.state.activeQuestion ? "active" : null}
                            onClick={this.toggleClass} >
                            
                            <a href="#toggle" class="d-flex justify-content-between" data-toggle="collapse" data-target="#faq4">
                                {items.question}
                                <i class="la la-plus"></i>
                            </a>
                        
                            </strong>
                            <div id="faq1" class="collapse show" data-parent="#accordion">
                                <div class="card-body">
                                    <p>{items.answer}</p>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
            </div>
            </div></div></div></div></div>
            </section>
        </div>
        
        );
    }
}

export default Faqs;