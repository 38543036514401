import React, { useState } from 'react';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { format } from 'date-fns';

// import AdapterDateFns from '@mui/lab/AdapterDateFns'
// import { TextField } from '@mui/material/';
// import { DatePicker, TimePicker, LocalizationProvider } from '@mui/lab';

import Header from './Header'

// class Home extends Component{
//     render(){
  function Home() {
  
  const alertMsg = "Passengers cannot exceed 9";
  const alertMsg1 = "Each adult can only accompany a maximun of one infant";
  const dateMsg = "Invalid date!";

  const myformat = format(new Date(), 'yyyy-MM-dd');

  const [oneWay, setOneWay] = useState(true);

  const [acounter, setACounter] = useState(1);
  const [ccounter, setCCounter] = useState(0);
  const [icounter, setICounter] = useState(0);

  const totalcount = (acounter + ccounter + icounter);

  const [exceed, setExceed] = useState(false);
  const [limitad, setLimitad] = useState(false);
  const [dateoptions, setDateoptions] = useState(false);


{/* Dropdown Toggle */}
const [dropdown, setDropdown] = useState(false);
const [doneBtn, setDoneBtn] = useState(false);


{/* Tabs */}
const [defaulttab, setDefault] = useState(true);
const [hotelt, setHotelt] = useState(false);
const [visast, setVisast] = useState(false);
const [ridest, setRidest] = useState(false);

  {/*Tabs Fragment from App.js*/}
  const defaultTab = () => {
    setDefault(setdtab => true)
    }
    
    const tabHotel = () => {
    setDefault(setdtab => false)
    setHotelt(sethtab => true);
    }
    
    const tabVisas = () => {
    setDefault(setdtab => false)
    setHotelt(sethtab => false);
    setVisast(setvtab => true)
    }
    
    const tabRides = () => {
    setDefault(setdtab => false)
    setHotelt(sethtab => false);
    setVisast(setvtab => false)
    setRidest(setrtab => true)
    }


  {/*Tabs Fragment from App.js*/}
  const openDropdown =()=> {
    if(doneBtn == true){
      setDropdown(openitems => false);
    }else{
      setDropdown(openitems => !openitems)
    }    
  };

  const clickOneWay = () =>{
setOneWay(current=> false);
};

const clickRoundTrip = () =>{
setOneWay(current=> true);
};


{/* Counter Fragment from App.js */}

const incrementCountA = () => {
if(acounter >= 9){
  setExceed(exceeded => !exceeded);
}else if(acounter + ccounter + icounter >= 9){
  setExceed(exceeded => !exceeded);
}else if(acounter < icounter || icounter > acounter){
  setLimitad(limexceeded => !limexceeded);
  setACounter(count => count + 1);
}else{
  setACounter(count => count + 1);
}
};

const incrementCountC = () => {
if(ccounter >= 9){
  setExceed(exceeded => !exceeded);
}else if(acounter + ccounter + icounter >= 9){
  setExceed(exceeded => !exceeded);
}else{
  setCCounter(count => count + 1);
}
};

const incrementCountI = () => {
if(icounter >= 9){
  setExceed(exceeded => !exceeded);
}else if(acounter + ccounter + icounter >= 9){
  setExceed(exceeded => !exceeded);
}else if(icounter >= acounter){
  setLimitad(limexceeded => !limexceeded);
}else{
  setICounter(count => count + 1);
}
};

const decrementCountA = () => {
if(acounter <= icounter){
  setLimitad(limexceeded => !limexceeded);
  setACounter(count => count + 1);
}else if(acounter <= 1){
  setACounter(count => count + 1);
}
setACounter(count => count - 1);
};

const decrementCountC = () => {
if(ccounter > 0){
  setCCounter(count => count - 1);
}
};

const decrementCountI = () => {
if(icounter > 0){
  setICounter(count => count - 1);
}
};

{/* Form Control */}
const [fromWhere, setFromWhere] = useState('');
const [toWhere, setToWhere] = useState('');
const [departing, setDeparting] = useState(myformat);
const [returning, setReturning] = useState(myformat);
const [flightClass, setFlightClass] = useState('Economy');


const [hotelName, setHotelName] = useState('');
const [checkin, setCheckin] = useState(myformat);
const [checkout, setCheckout] = useState(myformat);
const [rooms, setRooms] = useState('1');
const [guests, setGuests] = useState('1');

//Pick-Up
const [pickuplocation, setPickuplocation] = useState('');
const [pickupdestination, setPickupdestination] = useState('');
const [pickupdate, setPickupdate] = useState(myformat);
const [pickuptime, setPickuptime] = useState('');
const [carClass, setCarClass] = useState('Economy');

const [setdropoff, setSetdropoff] = useState(true);
const [setpickupform, setSetpickupform] = useState(false);

const clickPickup = () =>{
  setSetdropoff(current=> false);
  setSetpickupform(current=> true);
};

const clickDropoff = () =>{
  setSetdropoff(current=> true);
  setSetpickupform(current=> false);
};

//Drop-Off
const [destination, setDestination] = useState('');
const [airport, setAirport] = useState('');
const [dropoffdate, setDropoffdate] = useState(myformat);
const [dropofftime, setDropofftime] = useState('');


const navigate = useNavigate();


const submitFlight=(e) => {
  e.preventDefault();

  // const userflightdetails = { fromWhere, toWhere, departing, returning, flightClass, totalcount }

  if(!oneWay && departing < myformat){ {/* departing < myformat || returning < myformat || returning < departing */}
    alert("Invalid date!")
  }else if(departing < myformat && oneWay === true || returning < myformat && oneWay === true || returning < departing && oneWay === true){
    alert("Invalid date!")
  }else{
    navigate({
      pathname: "/flightdetails",
      search: createSearchParams({
        from: fromWhere,
        to: toWhere,
        departing: departing,
        returning: returning,
        class: flightClass,
        adult: acounter,
        child: ccounter,
        infant: icounter,
        all_passengers: totalcount
      }).toString()
    });
  }

}

const submitHotel=(e) => {
  e.preventDefault();

  const hoteldetails = { hotelName, checkin, checkout, rooms, guests }

  if(checkin < myformat || checkout < myformat || checkout < checkin){
    alert("Invalid date!")
  }else{

    navigate({
      pathname: "/hoteldetails",
      search: createSearchParams({
        hotel_name: hotelName,
        check_in: checkin,
        check_out: checkout,
        room_no: rooms,
        guest_no: guests,
      }).toString()
    });
  }
  
}

const submitRide=(e) => {
  e.preventDefault();

  const ridedetails = { pickuplocation, pickupdestination, pickupdate, pickuptime }

  if(pickupdate < myformat){
    alert("Invalid date!")
  }else{

    navigate({
      pathname: "/cardetails",
      search: createSearchParams({
        pickup_location: pickuplocation,
        pickup_destination: pickupdestination,
        car_class: carClass,
        pickup_date: pickupdate,
        pickup_time: pickuptime,
        
      }).toString()
    });
  }
  
}

const submitRideDrop=(e) => {
  e.preventDefault();

  const ridedetails_drop = { destination, airport, dropoffdate, dropofftime }
  
  if(dropoffdate < myformat){
    alert("Invalid date!")
  }else{

    navigate({
      pathname: "/cardetailsdrop",
      search: createSearchParams({
        your_destination: destination,
        airport: airport,
        car_class: carClass,
        dropoff_date: dropoffdate,
        dropoff_time: dropofftime,
        
      }).toString()
    });
  }
}



            return (
            <div>

    <Header pass={ defaulttab } pass1={ hotelt } pass2={ visast } pass3={ ridest } />                  

 {/* == Main Section Body == */}
    
    <section className="p-0 height-300 parallax-bg" style = {{position: 'relative'}}> {/*background: 'url(images/banner/03.jpg) no-repeat 0% 0%', background: 'linear-gradient(180deg,rgba(34,51,68,.69),rgba(34,51,68,0))',
    backgroundBlendMode: 'difference', backgroundSize: 'cover', */}
    <Carousel fade indicators={false} controls={false} interval={50000}>
      <Carousel.Item>
      <img className="banner-image d-block w-100" src="images/banner/htt-banner-001.jpg" alt="htt-banner" />
      <div class="banner-image-overlay"></div>
      <Carousel.Caption style={{right: '18%', left: '0', bottom: '45%'}}>
      <div className="container h-100">
        <div className="row justify-content-between align-items-center h-100" >
          <div className="col-md-8 mb-7">
            <p id="home-title-text">Book Best Flight and Hotel Deals</p>
          </div>
        </div>
      </div>
      </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img className="banner-image d-block w-100" src="images/banner/03.jpg" alt="htt-banner" />
      <div class="banner-image-overlay"></div>
      <Carousel.Caption style={{right: '18%', left: '0', bottom: '45%'}}>
      <div className="container h-100">
        <div className="row justify-content-between align-items-center h-100" >
          <div className="col-md-8 mb-7">
            {/* <p id="home-title-text">Search Flights and Hotels</p> */}
          </div>
        </div>
      </div>
      </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img className="banner-image d-block w-100" src="images/banner/htt-banner-002.jpg" alt="htt-banner" />
      <div class="banner-image-overlay"></div>
      <Carousel.Caption style={{right: '18%', left: '0', bottom: '45%'}}>
      <div className="container h-100">
        <div className="row justify-content-between align-items-center h-100" >
          <div className="col-md-8 mb-7">
            <p id="home-title-text">HT&T, Fly For Less...</p>
          </div>
        </div>
      </div>
      </Carousel.Caption>
      </Carousel.Item>
    </Carousel>  
      
    </section>
  
  {/* Whatsapp div */}
  <div className="whatsapp-div">
    <div className="talk-to-us">talk to us</div>
    <a class="float" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!" target="_blank" rel="noopener noreferrer">
	    <i id="my_float" class="fa fa-comment"></i>
    </a>
  </div>
  
  {/* Whatsapp div end */}

{/* <!-- ======================= Main banner --> */}

<div className="mt-lg-n9 mt-sm-0 pb-0 z-index-9 booking-search" style={{height: 'auto', marginTop: '-25%'}}>
  <div className="container ">
    <div className="row shadow border-radius-3">
      <div className="col-md-12 np">
        <div className="feature-box h-100">
          <div className="tab_container">
            <input id="tab2" type="radio" name="tabs" checked={ defaulttab } />
            <label for="tab2" onClick={ defaultTab }><i className="fa fa-plane"></i><span>Flights</span></label>
            <input id="tab1" type="radio" name="tabs" />
            <label for="tab1" onClick={ tabHotel }><i className="fas fa-building"></i><span>Hotels</span></label>
            <input id="tab4" type="radio" name="tabs" />
            <label for="tab4" onClick={ tabVisas }><i className="fas fa-passport"></i><span>Visas</span></label>
            <input id="tab3" type="radio" name="tabs" />
            <label for="tab3" onClick={ tabRides }><i className="fas fa-car"></i><span>Rides</span></label>

            <section id="content1" className="tab-content">
            <form onSubmit={ submitHotel }>
              <div className="row">
                
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 padding8">
                  <div className="form-group"> <span className="fas fa-map-marker-alt"></span>
                    <input className="form-control" type="text" placeholder="Hotel Location or Name " value={ hotelName } onChange={(e)=> setHotelName(e.target.value)} required />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                  <div className="form-group"> <span className="style-placeholder">Check-in</span><span className="far fa-calendar-alt"></span>
                    <input className="form-control" type="date" placeholder="Check-in" value={ checkin } onChange={(e)=> setCheckin(e.target.value)} required /> {/* id was id="datepicker"*/}
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                  <div className="form-group"> <span className="style-placeholder">Check-out</span><span className="far fa-calendar-alt"></span>
                    <input className="form-control" type="date" placeholder="Check-out" value={ checkout } onChange={(e)=> setCheckout(e.target.value)} required /> {/* id was id="datepicker-out"*/}
                  </div>
                </div>

                <div className="col-lg-1 col-md-6 col-sm-6 col-xs-12 padding8" id="rooms-id"> {/* col-lg-1 col-md-6 col-sm-6 col-xs-12 padding8 */}
                    <div className="form-group">
                      <select className="custom-select select-big"  value={ rooms } onChange={(e)=> setRooms(e.target.value)} required>
                        <option selected="">Rooms</option>
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 col-md-6 col-sm-6 col-xs-12 padding8" id="guests-id">
                    <div className="form-group">
                      <select className="custom-select select-big" value={ guests } onChange={(e)=> setGuests(e.target.value)} required>
                        <option selected="">Guests</option>
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                      </select>
                    </div>
                  </div>

                <div className="rooms-guests-holder">
                  <div className="rooms-holder"> {/* col-lg-1 col-md-6 col-sm-6 col-xs-12 padding8 */}
                    <div className="form-group">
                      <select className="custom-select select-big"  value={ rooms } onChange={(e)=> setRooms(e.target.value)} required>
                        <option selected="">Rooms</option>
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                      </select>
                    </div>
                  </div>
                  <div className="guests-holder">
                    <div className="form-group">
                      <select className="custom-select select-big" value={ guests } onChange={(e)=> setGuests(e.target.value)} required>
                        <option selected="">Guests</option>
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                  <div className="form-group">
                    <button className="btn btn-primary btn-lg btn-grad" type="submit">Continue</button>
                    
                  </div>
                </div>
                
              </div>
              </form>
            </section>

            
            <section id="content2" className="tab-content">
              {/* Trip Options */}
              <div className="trip-options">
                <span className="flight-trip-option-checked" onClick={ clickRoundTrip }>Round Trip</span>
                <span className="flight-trip-option" onClick={ clickOneWay }>One Way</span>
            
                {/* Class */}
                
                  <div className=""> {/*col-lg-1 col-md-6 col-sm-6 col-xs-12 padding8*/}
                      <div className="form-group">
                      <select className="flight-trip-option-class" value={ flightClass } onChange={(e)=> setFlightClass(e.target.value)} required> {/* custom-select select-big */}
                          <option selected="Economy">Economy</option>
                        
                          <option>Business</option>
                          <option>First Class</option>
                        </select>
                        
                      </div>
                    </div>
                    

                    {/* Passenger1 */}
                          <span className="flight-trip-option" onClick={ openDropdown }>{ totalcount } Passengers</span>
                          { dropdown && (<span className="msg-box">{ exceed ? alertMsg : limitad ? alertMsg1 : 'All good!' }</span>)}
                          { dropdown && (
                            <div className="mydropdown" x-placement="bottom-left" style={{willChange: 'transform'}}> {/*dropdown-menu-md dropdown-menu*/}
                            <div className="dropdown-item">
                            {/* <span className="msg-box">{ exceed ? alertMsg : limitad ? alertMsg1 : 'All good!' }</span> */}
                              <div className="item-row1 align-items-center">
                                <div className="col-4">
                                  <span className="counter-count">Adult</span>
                                </div>

                                <div className="col-6">
                                  <button className="counter-control" onClick={ decrementCountA }>‒</button>
                                  <span className="counter-count">{ acounter }</span>  
                                  <button className="counter-control" onClick={ incrementCountA }>+</button>
                                  
                                </div>
                              </div>

                              <div className="item-row align-items-center">
                                
                              <div className="col-4">
                                <span className="counter-count">Child</span>
                              </div>

                              <div className="col-6">
                                <button className="counter-control" onClick={ decrementCountC }>‒</button>
                                <span className="counter-count">{ ccounter }</span>  
                                <button className="counter-control" onClick={ incrementCountC }>+</button>
                              </div>
                              </div>

                              <div className="item-row align-items-center">
                                
                              <div className="col-4">
                                <span className="counter-count">Infant</span>
                              </div>
                                
                              <div className="col-6">
                                <button className="counter-control" onClick={ decrementCountI }>‒</button>
                                <span className="counter-count">{ icounter }</span>  
                                <button className="counter-control" onClick={ incrementCountI }>+</button>
                              </div>
                            </div>
                              <span className="done-button" onClick={ openDropdown } value={ totalcount }>{() => setDoneBtn(isdone => !isdone)}Done</span>  
                            </div>
                            
                          </div>
                          )}

              </div>

              <div className="trip-options-mobile">
                <div className="rtoneway">
                  <span className="flight-trip-option-checked" onClick={ clickRoundTrip }>Round Trip</span>
                  <span className="flight-trip-option" onClick={ clickOneWay }>One Way</span>
                </div>
                
            
                {/* Class */}
                
                  <div className=""> {/*col-lg-1 col-md-6 col-sm-6 col-xs-12 padding8*/}
                      <div className="form-group">
                      <select className="flight-trip-option-class" value={ flightClass } onChange={(e)=> setFlightClass(e.target.value)} required> {/* custom-select select-big */}
                          <option selected="Economy">Economy</option>
                        
                          <option>Business</option>
                          <option>First Class</option>
                        </select>
                        
                      </div>
                    </div>
                    

                    {/* Passenger1 */}
                          <span className="flight-trip-option-passengers" onClick={ openDropdown }>{ totalcount } Passengers</span>
                          { dropdown && (<span className="msg-box1">{ exceed ? alertMsg : limitad ? alertMsg1 : 'All good!' }</span>)}
                          { dropdown && (
                            <div className="mydropdown1" x-placement="bottom-left" style={{willChange: 'transform'}}> {/*dropdown-menu-md dropdown-menu*/}
                            <div className="dropdown-item">
                            {/* <span className="msg-box">{ exceed ? alertMsg : limitad ? alertMsg1 : 'All good!' }</span> */}
                              <div className="item-row1 align-items-center">
                                <div className="col-4">
                                  <span className="counter-count">Adult</span>
                                </div>

                                <div className="col-6">
                                  <button className="counter-control" onClick={ decrementCountA }>‒</button>
                                  <span className="counter-count">{ acounter }</span>  
                                  <button className="counter-control" onClick={ incrementCountA }>+</button>
                                  
                                </div>
                              </div>

                              <div className="item-row align-items-center">
                                
                              <div className="col-4">
                                <span className="counter-count">Child</span>
                              </div>

                              <div className="col-6">
                                <button className="counter-control" onClick={ decrementCountC }>‒</button>
                                <span className="counter-count">{ ccounter }</span>  
                                <button className="counter-control" onClick={ incrementCountC }>+</button>
                              </div>
                              </div>

                              <div className="item-row align-items-center">
                                
                              <div className="col-4">
                                <span className="counter-count">Infant</span>
                              </div>
                                
                              <div className="col-6">
                                <button className="counter-control" onClick={ decrementCountI }>‒</button>
                                <span className="counter-count">{ icounter }</span>  
                                <button className="counter-control" onClick={ incrementCountI }>+</button>
                              </div>
                            </div>
                              <span className="done-button" onClick={ openDropdown } value={ totalcount }>{() => setDoneBtn(isdone => !isdone)}Done</span>  
                            </div>
                            
                          </div>
                          )}

              </div>

              {/* Trip options end */}
              <form onSubmit={ submitFlight }>
              <div className="row">
                
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 padding8">
                  <div className="form-group"> <span className="fas fa-map-marker-alt"></span>
                    <input className="form-control" type="text" placeholder="From: City or Airport" value={ fromWhere } onChange={(e)=> setFromWhere(e.target.value)} required />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 padding8">
                  <div className="form-group"> <span className="fas fa-map-marker-alt"></span>
                    <input className="form-control" type="text" placeholder="To: City or Airport" value={ toWhere } onChange={(e)=> setToWhere(e.target.value)} required />
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                  <div className="form-group"> <span className="style-placeholder">Departing</span><span className="far fa-calendar-alt"></span>
                    <input className="form-control" type="date" placeholder="Departing" value={ departing } 
                    onChange={(e)=> setDeparting(e.target.value)
                      // {if(departing !== myformat){
                      //   setDateoptions(isinvalid => true)
                      // }
                      //   setDeparting( e.target.value )
                      // }}
                    }
                    required 
                    /> {/* id was datepicker-1 */}
                  </div>
                </div>
                { oneWay && (
                  <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                    <div className="form-group"><span className="style-placeholder">Returning</span> <span className="far fa-calendar-alt"></span>
                      <input className="form-control" type="date" id="end" autocomplete="off" placeholder="Returning" 
                      value={ returning }
                      onChange={(e) => setReturning(e.target.value)
                        // {if(returning[8] + returning[9] < departing[8] + departing[9] || returning !== myformat){
                        //   setDateoptions(isinvalid => true);
                        // }else{
                        //   setReturning(e.target.value);
                        // }}
                       } 
                      required /> {/* id was datepicker-2 */}
                    </div>
                  </div>
                )}
                
                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                  <div className="form-group">
                    
                    <button className="btn btn-primary btn-lg btn-grad" type="submit">Continue</button>
                    
                  </div>
                </div>
                
              </div>
              </form>
            </section>

            <section id="content3" className="tab-content">
              <div className="trip-options">
                  <span className="flight-trip-option-checked" onClick={ clickPickup }>Pick-Up</span>
                  <span className="flight-trip-option" onClick={ clickDropoff }>Drop-Off</span>
              
                  {/* Class */}
                  
                    <div className=""> {/*col-lg-1 col-md-6 col-sm-6 col-xs-12 padding8*/}
                        <div className="form-group">
                        <select className="flight-trip-option-class" value={ carClass } onChange={(e)=> setCarClass(e.target.value)} required> {/* custom-select select-big */}
                            <option selected="Economy">Economy</option>
                            
                            <option>Standard</option>
                            <option>Premium</option>
                          </select>
                          
                        </div>
                      </div>

                </div>

                <div className="trip-options-mobile">
                  <span className="flight-trip-option-checked" onClick={ clickPickup }>Pick-Up</span>
                  <span className="flight-trip-option" onClick={ clickDropoff }>Drop-Off</span>
              
                  {/* Class */}
                  
                    <div className=""> {/*col-lg-1 col-md-6 col-sm-6 col-xs-12 padding8*/}
                        <div className="form-group">
                        <select className="flight-trip-option-class" value={ carClass } onChange={(e)=> setCarClass(e.target.value)} required> {/* custom-select select-big */}
                            <option selected="Economy">Economy</option>
                            
                            <option>Standard</option>
                            <option>premium</option>
                          </select>
                          
                        </div>
                      </div>

                </div>

              { setpickupform && (
                <form onSubmit={ submitRide }>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 padding8">
                    <div className="form-group"> <span className="fas fa-map-marker-alt"></span>
                      <input className="form-control" type="text" placeholder="Airport" value={ pickuplocation } onChange={(e)=> setPickuplocation(e.target.value)} required />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 padding8">
                    <div className="form-group"> <span className="fas fa-map-marker-alt"></span>
                      <input className="form-control" type="text" placeholder="Destination" value={ pickupdestination } onChange={(e)=> setPickupdestination(e.target.value)} required />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                    <div className="form-group"> <span className="style-placeholder">Pick-up Date</span><span className="far fa-calendar-alt"></span>
                      <input className="form-control" type="date" placeholder="Pick-up Date" value={ pickupdate } onChange={(e)=> setPickupdate(e.target.value)} required />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                    <div className="form-group"> <span className="style-placeholder">Pick-up Time</span><span className="far fa-clock"></span>
                      <input className="form-control" type="text" placeholder="10:00 AM" value={ pickuptime } onChange={(e)=> setPickuptime(e.target.value)} required />
                      {/* <TimePicker label="Basic Time" value={ pickuptime } onChange={(newValue)=> setPickuptime(newValue)} renderInput={(params) => <TextField {...params} />} /> */}     
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                    <div className="form-group">
                      <button className="btn btn-primary btn-lg btn-grad" type="submit">Continue</button>
                    </div>
                  </div>
                </div>
                </form>
              )}

              {/* Car Drop-Off */}
              { setdropoff && (
                <form onSubmit={ submitRideDrop }>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 padding8">
                    <div className="form-group"> <span className="fas fa-map-marker-alt"></span>
                      <input className="form-control" type="text" placeholder="Location" value={ destination } onChange={(e)=> setDestination(e.target.value)} required />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 padding8">
                    <div className="form-group"> <span className="fas fa-map-marker-alt"></span>
                      <input className="form-control" type="text" placeholder="Airport" value={ airport } onChange={(e)=> setAirport(e.target.value)} required />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                    <div className="form-group"> <span className="style-placeholder">Drop-off Date</span><span className="far fa-calendar-alt"></span>
                      <input className="form-control" type="date" placeholder="Drop-off Date" value={ dropoffdate } onChange={(e)=> setDropoffdate(e.target.value)} required />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                    <div className="form-group"> <span className="style-placeholder">Drop-off Time</span><span className="far fa-clock"></span>
                      <input className="form-control" type="text" placeholder="10:00 AM" value={ dropofftime } onChange={(e)=> setDropofftime(e.target.value)} required /> 
                      {/* <TimePicker label="Time" value={ dropofftime } onChange={(newValue)=> setDropofftime(newValue)} renderInput={(params) => <TextField {...params} />} /> */}
                      
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 padding8">
                    <div className="form-group">
                      <button className="btn btn-primary btn-lg btn-grad" type="submit">Continue</button>
                    </div>
                  </div>
                </div>
                </form>
              )}
            </section>


            <section id="content4" className="tab-content">
              <div className="row">
                {/* Uk Visa */}
                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 padding8">
                  <div className="visa-panel">
                    <a href="https://api.whatsapp.com/send?phone=2348066603578&text=UK Visa Enquiry"><h3 style={{color: '#005357'}}>UK Visa</h3></a>
                  </div>
                </div>

                {/* Dubai Visa */}
                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 padding8">
                  <div className="visa-panel1">
                    <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Dubai Visa Enquiry"><h3 style={{color: '#005357'}}>Dubai Visa</h3></a>
                  </div>
                </div>
                
                {/* Other Visas */}
                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 padding8">
                  <div className="visa-panel">
                    <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Other Visas Enquiry"><h3 style={{color: '#005357'}}>Other Visas</h3></a>
                  </div>
                </div>
              </div>
            </section>
            
            </div> {/*was section before */}
          </div>
        </div>
      </div>
    </div>
  </div>
 {/*</div> was section before */}

 {/* Why choose us */}
 <section className="bg-light pt80 pb60 solutions" style={{height: 'auto'}}>
  <div className="container">
    <div className="row">
      {/* <div className="col-md-8 mx-auto text-center mb-5">
        <h2 className="title text-center">Why Choose Us</h2>
        <p>Sorem ipsum dolor sit amet, consectetur adipisicing Suscipit votas aperiam Sorem ipsum dolor consectur adipisicing elit.</p>
      </div> */}
    </div>
    <div className="row-custom">
      <div className="what-we-do"> {/* col-sm-6 col-lg-3 mb-4 mb-lg-0 */}
        <div className="" style ={{display: 'flex', justifyContent: 'center'}}> <span className="d-block mb-4"><img src="images/fast-booking.png" style={{width: 60, height: 60, marginRight: 10, marginTop: '-10%'}} /></span> 
          <span className="what-we-do-text">Fast Booking</span>
        </div>
      </div>
      <div className="what-we-do">
        <div className="" style ={{display: 'flex', justifyContent: 'center'}}> <span className="d-block mb-4"><img src="images/affordable.png" style={{width: 50, height: 50, marginRight: 10}} /></span> 
          <span className="what-we-do-text">Affordable Packages</span>
        </div>
      </div>
      <div className="what-we-do">
        <div className="" style ={{display: 'flex', justifyContent: 'center'}}>  <span className="d-block mb-4"><img src="images/grow-with-us.png" style={{width: 50, height: 50, marginRight: 10}} /></span> 
          <span className="what-we-do-text">Earn & Grow</span>
        </div>
      </div>
      <div className="what-we-do">
        <div className="" style ={{display: 'flex', justifyContent: 'center'}}>  <span className="d-block mb-4"><img src="images/support.png" style={{width: 60, height: 60, marginRight: 10}} /></span> 
          <span className="what-we-do-text">24/7 Support</span>
          {/* <span style={{fontSize: 15, marginTop: 3, textAlign: 'left', height: 'auto', color: '#eee'}}>Our customer support team are swift to answering all your travel questions</span> */}
        </div>
        
      </div>
    </div>

    <div className="row-custom-mobile">
      <div className="what-we-do"> {/* col-sm-6 col-lg-3 mb-4 mb-lg-0 */}
        <div className="" style ={{display: 'flex', justifyContent: 'center'}}> <span className="d-block mb-4"><img src="images/fast-booking.png" style={{width: 60, height: 60, marginRight: 10}} /></span> 
          <span className="what-we-do-text">Fast Booking</span>
        </div>
      </div>
      <div className="what-we-do">
        <div className="" style ={{display: 'flex', justifyContent: 'center'}}> <span className="d-block mb-4"><img src="images/affordable.png" style={{width: 50, height: 50, marginRight: 10}} /></span> 
          <span className="what-we-do-text">Affordable Plans</span>
        </div>
      </div>
      <div className="what-we-do">
        <div className="" style ={{display: 'flex', justifyContent: 'center'}}>  <span className="d-block mb-4"><img src="images/grow-with-us.png" style={{width: 50, height: 50, marginRight: 10}} /></span> 
          <span className="what-we-do-text">Earn & Grow</span>
        </div>
      </div>
      <div className="what-we-do">
        <div className="" style ={{display: 'flex', justifyContent: 'center'}}>  <span className="d-block mb-4"><img src="images/support.png" style={{width: 60, height: 60, marginRight: 10}} /></span> 
          <span className="what-we-do-text">24/7 Support</span>
          {/* <span style={{fontSize: 15, marginTop: 3, textAlign: 'left', height: 'auto', color: '#eee'}}>Our customer support team are swift to answering all your travel questions</span> */}
        </div>
        
      </div>
    </div>
  </div>
</section>
 {/* why choose us end */}
 
{/* Internal Slides */}
<section> 
<div className="mycustom-slider">
  <img src="images/banner/htt-ads-banner.gif" alt="ads-banner" style={{width: '100%'}}/>
</div>

<div className="mycustom-slider-tab">
  <Carousel slide={false} indicators={false} controls={false} interval={2500}>
    <Carousel.Item>
      <img className="d-block w-100" src="images/banner/tab1.jpg" alt="ads-banner" style={{width: '100%'}}/>
    </Carousel.Item>
    <Carousel.Item>
      <img className="d-block w-100" src="images/banner/tab2.jpg" alt="ads-banner" style={{width: '100%'}}/>
    </Carousel.Item>
    <Carousel.Item>
      <img className="d-block w-100" src="images/banner/tab3.jpg" alt="ads-banner" style={{width: '100%'}}/>
    </Carousel.Item>
  </Carousel>
</div>

<div className="mycustom-slider-mobile">
<Carousel slide={false} indicators={false} controls={false} interval={3000}>
    <Carousel.Item>
      <img className="d-block w-100" src="images/banner/mobi1.jpg" alt="ads-banner" style={{width: '100%'}}/>
    </Carousel.Item>
    <Carousel.Item>
      <img className="d-block w-100" src="images/banner/mobi2.jpg" alt="ads-banner" style={{width: '100%'}}/>
    </Carousel.Item>
    <Carousel.Item>
      <img className="d-block w-100" src="images/banner/mobi3.jpg" alt="ads-banner" style={{width: '100%'}}/>
    </Carousel.Item>
  </Carousel>
</div>
</section>
{/* Internal Slides End */}

<section className="Categories pt80 pb60">
  <div className="container">
    <div className="row mb-5">
      <div className="col-md-8">
        <p className="subtitle text-secondary nopadding">TRENDING</p>
        <h1 className="paddtop1 font-weight lspace-sm">Popular Places & Hotels</h1>
      </div>
      {/* <div className="col-md-4 d-lg-flex align-items-center justify-content-end"><a href="google.com" className="blist text-sm ml-2"> See all cities<i className="fas fa-angle-double-right ml-2"></i></a></div> */}
    </div>
    <div className="row">
      <div className="col-md-6"> <a href="google.com">
        <div className="list-mig-like-com">
          <div className="list-mig-lc-img"> <img src="images/tour/home.jpg" alt=""/></div>
          <div className="list-mig-lc-con">
            <h5>Dubai</h5>
            <p>210 Hotels </p>
          </div>
        </div>
        </a> </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-6"> <a href="google.com">
            <div className="list-mig-like-com">
              <div className="list-mig-lc-img"> <img src="images/tour/home2.jpg" alt=""/> </div>
              <div className="list-mig-lc-con list-mig-lc-con2">
                <h5>London</h5>
                <p>400 Hotels </p>
              </div>
            </div>
            </a> </div>
          <div className="col-md-6"> <a href="google.com">
            <div className="list-mig-like-com">
              <div className="list-mig-lc-img"> <img src="images/tour/home3.jpg" alt=""/> </div>
              <div className="list-mig-lc-con list-mig-lc-con2">
                <h5>Australia</h5>
                <p>97 Hotels </p>
              </div>
            </div>
            </a> </div>
          <div className="col-md-6"> <a href="google.com">
            <div className="list-mig-like-com">
              <div className="list-mig-lc-img"> <img src="images/tour/home4.jpg" alt=""/> </div>
              <div className="list-mig-lc-con list-mig-lc-con2">
                <h5>Singapore</h5>
                <p>79 Hotels </p>
              </div>
            </div>
            </a> </div>
          <div className="col-md-6"> <a href="google.com">
            <div className="list-mig-like-com">
              <div className="list-mig-lc-img"> <img src="images/tour/home1.jpg" alt=""/> </div>
              <div className="list-mig-lc-con list-mig-lc-con2">
                <h5>Zanzibar</h5>
                <p>104 Hotels </p>
              </div>
            </div>
            </a> </div>
        </div>
      </div>
    </div>
  </div>
</section>

{/* Internal Slides */}
{/* <section> 
<div className="mycustom-slider">
  <div className="inner-slider">
    <img src="images/tour/vac-tour1.jpg" alt="vacation & tour" class="img-fluid" />
    <img src="images/tour/vac-tour2.jpg" alt="vacation & tour" class="img-fluid" />
    <img src="images/tour/vac-tour3.jpg" alt="vacation & tour" class="img-fluid" />
    <img src="images/tour/vac-tour5.png" alt="vacation & tour" class="img-fluid" />
    <img src="images/tour/vac-tour6.jpg" alt="vacation & tour" class="img-fluid" />
  </div>
  
  {/* <div className="swiper-container detail-slider slider-gallery" style={{height: 200, width: '100%'}}> 
    
    <div className="swiper-wrapper"> 
      
      <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide"><a data-toggle="gallery-top" title="vacation & tour"><img src="images/tour/vac-tour1.jpg" alt="vacation & tour" class="img-fluid" /></a></div>
      <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide"><a data-toggle="gallery-top" title="vacation & tour"><img src="images/tour/vac-tour2.jpg" alt="vacation & tour" class="img-fluid" /></a></div>
      <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide"><a data-toggle="gallery-top" title="vacation & tour"><img src="images/tour/vac-tour3.jpg" alt="vacation & tour" class="img-fluid" /></a></div>
      <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide"><a data-toggle="gallery-top" title="vacation & tour"><img src="images/tour/vac-tour4" alt="vacation & tour" class="img-fluid" /></a></div>
      <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide"><a data-toggle="gallery-top" title="vacation & tour"><img src="images/tour/vac-tour5.jpg" alt="vacation & tour" class="img-fluid" /></a></div>
      <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide"><a data-toggle="gallery-top" title="vacation & tour"><img src="images/tour/vac-tour6.jpg" alt="vacation & tour" class="img-fluid" /></a></div>
    </div>
    <div className="swiper-pagination swiper-pagination-white"></div>
    <div className="swiper-button-prev swiper-button-white"></div>
    <div className="swiper-button-next swiper-button-white"></div>
  </div> */}
{/*</div>*/}
{/* </section> */}

<div className="container" style={{marginTop: 30}}>
  <div className="row mb-5">
      <div className="col-md-8">
        <p className="subtitle text-secondary nopadding">Vacations & Tours</p>
        <h1 className="paddtop1 font-weight lspace-sm">Trending</h1>
      </div>
     
    </div>

<div className="swiper-container detail-slider slider-gallery" id="process-slider"> 
      
      <div className="swiper-wrapper">

        <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" id="slider-width" style={{marginLeft: 30, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}><a data-toggle="gallery-top" title="South Africa">
  
          <img src="images/tour/vac-tour1.jpg" alt="Zanzibar" class="img-fluid" />
            <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-tour-title">Zanzibar Full Tour Package</span>
              <span className="visa-tour-tagline">Inclusive</span>
              <span className="visa-tour-tagline">Visa & Flight ticket, Hotel Accommodation & breakfast</span>
              <span className="visa-tour-tagline">Airport transfers, Tours</span>
              
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tour-title">Price</span>
                  <span className="starting-tour-price">₦1.5m per person sharing</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 20, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 10, fontWeight: '600'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div> 
          </a></div>
          
        <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" style={{marginLeft: 30}}><a data-toggle="gallery-top" title="London">
        
          <img src="images/tour/vac-tour2.jpg" alt="Mauritius" class="img-fluid" />
          <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-tour-title">Explore Mauritius. Full packaged trip</span>
            <span className="visa-tour-tagline">Inclusive</span>
            <span className="visa-tour-tagline">Flight ticket, Hotel Accommodation & breakfast</span>
            
            <span className="visa-tour-tagline">Airport transfers, Tours</span>
            
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tour-title">Price</span>
                  <span className="starting-tour-price">₦1.5m per person sharing</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 20, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 10, fontWeight: '600'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div>
          
          </a>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" style={{marginLeft: 30}}><a data-toggle="gallery-top" title="Work Visa">
          <img src="images/tour/vac-tour3.jpg" alt="Maldives" class="img-fluid" />
          <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-tour-title">Explore Maldives. Full packaged trip</span>
            <span className="visa-tour-tagline">Inclusive</span>
            <span className="visa-tour-tagline">Visa & Flight ticket, Hotel Accommodation & breakfast</span>
            
            <span className="visa-tour-tagline">Airport transfers, Tours</span>
            
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tour-title">Price</span>
                  <span className="starting-tour-price">₦1.9m per person sharing</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 20, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 10, fontWeight: '600'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div>
        </a></div>
        
        <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" style={{marginLeft: 30}}><a data-toggle="gallery-top" title="Morocco">
          <img src="images/tour/vac-tour4.jpg" alt="Singapore" class="img-fluid" />
          <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-tour-title"> Singapore full packaged trip</span>
              <span className="visa-tour-tagline">Inclusive</span>
              <span className="visa-tour-tagline">Visa & Flight ticket, Hotel Accommodation & breakfast</span>
              
              <span className="visa-tour-tagline">Airport transfers, Tours</span>
              
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tour-title">Price</span>
                  <span className="starting-tour-price">₦1.8m per person sharing</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 20, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 10, fontWeight: '600'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div>
          </a></div>

          <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" style={{marginLeft: 30}}><a data-toggle="gallery-top" title="Morocco">
          <img src="images/tour/vac-tour4.jpg" alt="Malaysia" class="img-fluid" />
          <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-tour-title"> Malaysia full packaged trip</span>
              <span className="visa-tour-tagline">Inclusive</span>
              <span className="visa-tour-tagline">Visa & Flight ticket, Hotel Accommodation & breakfast</span>
              
              <span className="visa-tour-tagline">Airport transfers, Tours</span>
              
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tour-title">Price</span>
                  <span className="starting-tour-price">₦900k per person sharing</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 20, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 10, fontWeight: '600'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div>
          </a></div>

        <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" style={{marginLeft: 30}}><a data-toggle="gallery-top" title="Morocco">
          <img src="images/tour/vac-tour4.jpg" alt="Nairobi" class="img-fluid" />
          <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-tour-title"> Explore Nairobi. Full packaged trip</span>
              <span className="visa-tour-tagline">Inclusive</span>
              <span className="visa-tour-tagline">Visa & Flight ticket, Hotel Accommodation & breakfast</span>
              
              <span className="visa-tour-tagline">Airport transfers, Tours</span>
              
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tour-title">Price</span>
                  <span className="starting-tour-price">₦1.1m per person sharing</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 20, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 10, fontWeight: '600'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div>
          </a></div>
        
        
      </div>
      
      <div className="swiper-pagination swiper-pagination-white"></div>
      <div className="swiper-button-prev swiper-button-white"></div>
      <div className="swiper-button-next swiper-button-white"></div>
    </div>
</div>


{/* Internal Slides End */}


<section className="grayBG pt80 pb80" style={{marginTop: 60}}>
  <div className="container ">
    <div className="row">
      <div className="col-md-8 mx-auto text-center mb-5">
        <h2 className="title text-center">Our Services</h2>
        <p>We offer a wide spectrum of travel services which include</p>
      </div>
    </div>
    <div className="row">
      <div className="col-md-3" style = {{background: '#FBF2E4'}}>
        <div className="feature-box h-100 text-center px-4 py-5">
          <div className="feature-box-icon"><img className="w-25" src="images/book-flight.png" alt="Flight Booking"/></div>
          <h3 className="feature-box-title">Flight Booking</h3>
          
          <p className="feature-box-desc">You fly more and pay less when you book your flight tickets, Local & International with Hazel Travels & Tours. We offer the best travel deals.</p>
        </div>
      </div>
      <div className="col-md-3" style = {{background: '#e4f3f4'}}>
      <div className="feature-box h-100 text-center px-4 py-5">
          <div className="feature-box-icon"><img className="w-25" src="images/visa-advisory.png" alt="Visa Advisory"/></div>
          <h3 className="feature-box-title">Visa Advisory</h3>
          <p className="feature-box-desc">We can assist you in all your visa processing, travel insurance, and general consulttion regards to all your travel needs.</p>
        </div>
      </div>
      <div className="col-md-3" style = {{background: '#e4f3f4'}}>
      <div className="feature-box h-100 text-center px-4 py-5">
          <div className="feature-box-icon"><img className="w-25" src="images/hotel-booking.png" alt="Hotel Booking"/></div>
          <h3 className="feature-box-title">Hotel Booking</h3>
          <p className="feature-box-desc">Save up to 15% when you book your hotel reservations with Hazel Travels & Tours. We offer the best discounted price world wide.</p>
        </div>
      </div>
      <div className="col-md-3" style = {{background: '#FBF2E4'}}>
        <div className="feature-box h-100 text-center px-4 py-5">
          <div className="feature-box-icon"><img className="w-25" src="images/tour.png" alt="Vacation and Tour"/></div>
          <h3 className="feature-box-title">Vacations & Tours</h3>
          <p className="feature-box-desc">We are always committed to offering travel services of the highest quality. Experience traveling like never before by booking a full packaged trip with Hazel Travels & Tours.</p>
        </div>
      </div>
    </div>
  </div>
</section>


{/* Visas now processing slide */}

<section className="Categories pt80 pb60 ">
  <div className="container">
<div className="row mb-5">
      <div className="col-md-8">
        <p className="subtitle text-secondary nopadding">visas</p>
        <h1 className="paddtop1 font-weight lspace-sm">Now Processing</h1>
      </div>
     
    </div>
    
    
    <div className="swiper-container detail-slider slider-gallery" id="process-slider"> 
      
      <div className="swiper-wrapper">

        <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" id="slider-width" style={{marginLeft: 30, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}><a data-toggle="gallery-top" title="South Africa">
  
          <img src="images/tour/Ht&t-Canada.jpg" alt="Canada work visa" class="img-fluid" />
            <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-title">Canada Work Visa</span>
              <span className="visa-tagline">Now processing</span>
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tagline">Starting from</span>
                  <span className="starting-price">$2,500</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 22, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 12, fontWeight: '700'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div> 
          </a></div>
          
        <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" style={{marginLeft: 30}}><a data-toggle="gallery-top" title="London">
        
          <img src="images/tour/Ht&t USA.jpg" alt="USA work visa" class="img-fluid" />
          <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-title">USA Work Visa</span>
              <span className="visa-tagline">Now processing</span>
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tagline">Starting from</span>
                  <span className="starting-price">$3,500</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 22, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 12, fontWeight: '700'}}>LEARN MORE</a>
                </div> {/* Whatsapp icon before Learn more text*/}
              </div>
              
            </div>
          
          </a>
        </div>
        {/* <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" style={{marginLeft: 30}}><a data-toggle="gallery-top" title="Work Visa">
          <img src="images/tour/zanzibar.jpg" alt="Work Visa" class="img-fluid" />
          <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-title">Work Visa</span>
              <span className="visa-tagline">Now processing</span>
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tagline">Starting from</span>
                  <span className="starting-price">₦650,000</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 22, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 12, fontWeight: '700'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div>
        </a></div> */}
        
        <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" style={{marginLeft: 30}}><a data-toggle="gallery-top" title="Morocco">
          <img src="images/tour/Ht&t-Australia.jpg" alt="Australia work visa" class="img-fluid" />
          <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-title">Australia Work Visa</span>
              <span className="visa-tagline">Now processing</span>
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tagline">Starting from</span>
                  <span className="starting-price">$2,000</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 22, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 12, fontWeight: '700'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div>
          </a></div>
        <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" style={{marginLeft: 30}}><a data-toggle="gallery-top" title="Tunisia">
          <img src="images/tour/Ht&t New Zealand.jpg" alt="New Zealand work visa" class="img-fluid" />
          <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-title">New Zealand Work Visa</span>
              <span className="visa-tagline">Now processing</span>
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tagline">Starting from</span>
                  <span className="starting-price">$1,500</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 22, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 12, fontWeight: '700'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div>
          </a></div>
        <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" style={{marginLeft: 30}}><a data-toggle="gallery-top" title="e-tourist">
          <img src="images/tour/Ht&t Turkey.jpg" alt="Turkey 1 year resident permit" class="img-fluid" />
          <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-title">Turkey 1 Year Resident Permit</span>
              {/* <span className="visa-tagline">Now processing</span> */}
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tagline">Starting from</span>
                  <span className="starting-price">$6,500</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 22, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 12, fontWeight: '700'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div>
          </a></div>

          <div className="col-lg-4 col-md-6 col-sm-12 swiper-slide" style={{marginLeft: 30}}><a data-toggle="gallery-top" title="e-tourist">
          <img src="images/tour/Ht&t-Qatar.jpg" alt="Qatar work visa" class="img-fluid" />
          <div className="img-overlay">
            <div className="up-holder">
            <span className="visa-title">Qatar Work Visa</span>
              <span className="visa-tagline">Now processing</span>
            </div>
              <div className="down-holder">
                <div>
                  <span className="visa-tagline">Starting from</span>
                  <span className="starting-price">$3,500</span>
                </div>
                
                <div className="visa-learnmore-option">
                <i className="fab fa-whatsapp" style={{fontSize: 22, color: '#fff', marginRight: 5}}></i>
                  <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Tourist-Visa-Enquiry!!" style={{color: '#fff', fontSize: 12, fontWeight: '700'}}>LEARN MORE</a>
                </div>
              </div>
              
            </div>
          </a></div>
        
      </div>
      
      <div className="swiper-pagination swiper-pagination-white"></div>
      <div className="swiper-button-prev swiper-button-white"></div>
      <div className="swiper-button-next swiper-button-white"></div>
    </div>
    
  
  </div>
</section>
{/* Visas slide end */}

{/* <!-- ======================= service --> */}
<section className="service pt80 pb80 service-home">
  <div className="container">
    <div className="row">
      <div className="col-12 col-lg-8 mx-auto">
        <div className="title text-center">
          <h2>What our clients are saying</h2>
          <p className="mb-0">Reviews from real clients of Hazel Travels & Tours.</p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-4 mt-30">
        <div className="featureBox icon-grad h-100">
          <div className="feature-box-icon">
            {/* <i className="fab fa-avianex"></i> */}
            <img src="images/testimonials/adebusola.png" alt="testimonial 1"/>
          </div>
          <h3 className="feature-box-title">Adebusola Osifeso</h3>
          <p className="feature-box-desc">Hazel Travels And Tours has been my life save when it comes to bookings expecially emergencies.</p>
          <a className="mt-3" href="https://www.google.com/search?q=hazel+travels+and+tours&client=firefox-b-d&sxsrf=ALiCzsbYzjsEiJgogPQu651CDkbj9BIqDw%3A1670790093491&ei=zTuWY8zCHd2kkdUP1MuR4AY&ved=0ahUKEwiM3ZCQsvL7AhVdUqQEHdRlBGwQ4dUDCA4&uact=5&oq=hazel+travels+and+tours&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwA0oECEEYAEoECEYYAFDtHliwSWCXTWgBcAF4AIABAIgBAJIBAJgBAKABAcgBCMABAQ&sclient=gws-wiz-serp#">HT&T Client</a> </div>
      </div>
      <div className="col-md-4 mt-30">
        <div className="featureBox icon-grad h-100">
          <div className="feature-box-icon">
            {/* <i className="fab fa-avianex"></i> */}
            <img src="images/testimonials/babalakin ayodele.png" alt="testimonial 1"/>
          </div>
          <h3 className="feature-box-title">Babalakin Ayodele</h3>
          <p className="feature-box-desc">Prompt service and great customer experience.</p>
          <a className="mt-3" href="https://www.google.com/search?q=hazel+travels+and+tours&client=firefox-b-d&sxsrf=ALiCzsbYzjsEiJgogPQu651CDkbj9BIqDw%3A1670790093491&ei=zTuWY8zCHd2kkdUP1MuR4AY&ved=0ahUKEwiM3ZCQsvL7AhVdUqQEHdRlBGwQ4dUDCA4&uact=5&oq=hazel+travels+and+tours&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwA0oECEEYAEoECEYYAFDtHliwSWCXTWgBcAF4AIABAIgBAJIBAJgBAKABAcgBCMABAQ&sclient=gws-wiz-serp#">HT&T Client</a> </div>
      </div>
      <div className="col-md-4 mt-30">
        <div className="featureBox icon-grad h-100">
          <div className="feature-box-icon">
            {/* <i className="fab fa-avianex"></i> */}
            <img src="images/testimonials/vivian-jide.png" alt="testimonial 1"/>
          </div>
          <h3 className="feature-box-title">Vivian Jide-Osode</h3>
          <p className="feature-box-desc">Hazel Travel has never failed when it concerns making it's clients enjoy their trip</p>
          <a className="mt-3" href="https://www.google.com/search?q=hazel+travels+and+tours&client=firefox-b-d&sxsrf=ALiCzsbYzjsEiJgogPQu651CDkbj9BIqDw%3A1670790093491&ei=zTuWY8zCHd2kkdUP1MuR4AY&ved=0ahUKEwiM3ZCQsvL7AhVdUqQEHdRlBGwQ4dUDCA4&uact=5&oq=hazel+travels+and+tours&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwAzIKCAAQRxDWBBCwA0oECEEYAEoECEYYAFDtHliwSWCXTWgBcAF4AIABAIgBAJIBAJgBAKABAcgBCMABAQ&sclient=gws-wiz-serp#">HT&T Client</a> </div>
      </div>
    </div>
  </div>
</section>
{/* <!-- ======================= service --> */}


{/* <!-- ======================= CTA --> */}
    <div class="section section-padding--sm bg-black">
                <div class="container">

                    <div class="cta-content">
                        <h2 class="title h1 text-white">We meet all your travel needs</h2>
                        <Link to="/contact" class="btn btn-light-outline btn-hover-primary">Contact Us</Link>
                    </div>

                </div>
    </div>     
 </div>
);
  //  }


}


export default Home;

