import { Link } from 'react-router-dom';


function Header (props) {

  const pass = props.pass;
  const pass1 = props.pass1;
  const pass2 = props.pass2;
  const pass3 = props.pass3;

    
        return (
            <div>
          
 <header className="header-static navbar-sticky navbar-light shadow"> 
  {/* Search */}
  <div className="top-search collapse bg-light" id="search-open" data-parent="#search">
    <div className="container">
      <div className="row position-relative">
        <div className="col-md-8 mx-auto py-5">
          <form>
            <div className="input-group">
              <input className="form-control border-radius-right-0 border-right-0" type="text" name="search" autofocus placeholder="What are you looking for?" />
              <button type="button" className="btn btn-grad border-radius-left-0 mb-0">Search</button>
            </div>
          </form>
        </div>
        <a className="position-absolute top-0 right-0 mt-3 mr-3" data-toggle="collapse" href="#search-open"><i className="fas fa-window-close"></i></a> </div>
    </div>
  </div>
  {/* End Search */}
  
  {/* Navbar top start */}
  <div className="navbar-top d-none d-lg-block">
    <div className="container">
      <div className="d-flex justify-content-between align-items-center"> 
        {/* <!-- navbar top Left--> */}
        <div className="d-flex align-items-center"> 
          {/* <!-- Language --> */}
          <span role="button" id="dropdownLanguage"> <img className="dropdown-item-icon" src="images/flag/uk.svg" alt="" /> English </span>
          
          {/* <!-- Top info --> */}
          <ul className="nav list-unstyled ml-3">
            <li className="nav-item mr-3"> <a className="navbar-link" href="google.com"><strong>Phone:</strong> (234) 806 660 3578</a> </li>
            <li className="nav-item mr-3"> <a className="navbar-link" href="mailto:reservations.hazeltravels@gmail.com"><strong>Email:</strong> reservations.hazeltravels@gmail.com</a> </li>
          </ul>
        </div>
        
        {/* <!-- navbar top Right--> */}
        <div className="d-flex align-items-center"> 
          {/* <!-- Top Account --> */}
          {/* <div className="dropdown"> <a className="dropdown-toggle" href="google.com" role="button" id="dropdownAccount" data-toggle="dropdown" /*aria-haspopup="true"*/ /*aria-expanded="false"><i className="far fa-user mr-2"></i>Account </a> */}
            {/* <div className="dropdown-menu mt-2 shadow" aria-labelledby="dropdownAccount"> <a className="dropdown-item" href="sign-in.html">Log In</a> <a className="dropdown-item" href="sign-up.html">Register</a> <a className="dropdown-item" href="google.com">Settings</a> </div> */}
          {/* </div> */}
          {/* <!-- top link --> */}
          <ul className="nav">
            <li className="nav-item"> <a className="nav-link" href="#link.html">Connect wit us</a> </li>
          </ul>
          {/* <!-- top social --> */}
          <ul className="social-icons">
            <li className="social-icons-item social-facebook m-0"> <a className="social-icons-link w-auto px-2" href="https://web.facebook.com/hazeltravelsandtours"><i className="fab fa-facebook-f"></i></a> </li>
            <li className="social-icons-item social-twitter m-0"> <a className="social-icons-link w-auto px-2" href="https://twitter.com/hazeltravels_"><i className="fab fa-twitter"></i></a> </li>
            <li className="social-icons-item social-instagram m-0"> <a className="social-icons-link w-auto pl-2" href="https://instagram.com/hazeltravelsandtours"><i className="fab fa-instagram"></i></a> </li>
            <li className="social-icons-item social-linkedin m-0"> <a className="social-icons-link w-auto px-2" href="https://linkedin.com/company/hazel-travels-and-tours"><i className="fab fa-linkedin"></i></a> </li>
            <li className="social-icons-item social-tiktok m-0"> <a className="social-icons-link w-auto pl-2" href="https://tiktok.com"><i className="fa fa-tiktok"></i></a> </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  {/* Navbar top End */}
  
  {/* <!-- Logo Nav Start --> */}
  <nav className="navbar navbar-expand-lg">
    <div className="container"> 
      {/* <!-- Logo -->  */}
      {/* <a className="navbar-brand" href="index.html"> <img src="images/logo-header.png" alt="travelgo" /> </a>  */}
      <Link to="/" className="navbar-brand"> <img src="images/logo-header.png" alt="ht&t" /> </Link> 
      
      {/* <!-- Menu opener button --> */}
      <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"> </span> </button>
      {/* <!-- Main Menu Start --> */}
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav ml-auto">
          {/* <!-- Menu item 1 Demos--> */}
          
          {/* <li className="nav-item dropdown active"> <a className="nav-link dropdown-toggle" href="google.com" id="demosMenu" data-toggle="dropdown" /*aria-haspopup="true" */}
          <li className="nav-item dropdown active"> <Link to="/"><span className="nav-link">Flights</span></Link></li>
          <li className="nav-item"><label for="tab1" onClick={() => pass1 }><span className="nav-link" style={{cursor: 'pointer'}}>Hotels</span></label></li>

          <li className="nav-item dropdown"><label for="tab4" onClick={() => pass2 }><a className="nav-link dropdown-toggle" style={{cursor: 'pointer'}} id="docMenu" data-toggle="dropdown">Visas</a></label>
            <ul className="dropdown-menu" aria-labelledby="docMenu">
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Australia Work Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Thailand Work Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Poland Work Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Maldives Work Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Qatar Work Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Oman Work Visa</a></li>

              <li><a className="dropdown-item" href="cruise-index.html">UK Study Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">UK Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">US Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Canada Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">France Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Turkey Tourist Visa</a></li>

              <li><a className="dropdown-item" href="cruise-index.html">Tunisia Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Spain Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Singapore Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Sweden Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Morocco Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Uganda Tourist Visa</a></li>

              <li><a className="dropdown-item" href="cruise-index.html">Kenya Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Tanzania Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">South Africa Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Rwanda Tourist Visa</a></li>
              <li><a className="dropdown-item" href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!">Dubai Tourist Visa</a></li>
            </ul>
          </li>

          <li className="nav-item"><label for="tab3" onClick={() => pass3 }><span className="nav-link" style={{cursor: 'pointer'}}>Rides</span></label></li>
          
        </ul>
      </div>
      <div className="navbar-nav"> 
        
        <div className="nav-item search border-0 pl-3 pr-0 px-lg-2" id="search"> <a className="nav-link" data-toggle="collapse" href="#search-open"><i className="fas fa-search"></i></a> </div>
        
        <div className="nav-item border-0 d-none d-lg-inline-block align-self-center"> <a href="https://api.whatsapp.com/send?phone=2348066603578&text=Hello!" className=" btn btn-sm btn-grad text-white mb-0">Enquiry</a> </div>
      </div>
    </div>
  </nav>
</header>
            </div>
        );
}


export default Header;